/**
 * クラス名：共通API
 * 説明：システム全体共通使うAPIへの要求関数を記載するファイルである。
 * 作成者：ナンス
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

import { error, authorizeError, complete } from './CommonModal';
import { ErrorMessage } from './Message';
import { updateAccessToken, getAccessToken } from './Common';

export const loginPath = process.env.REACT_APP_FRONTEND_URL;

//　ログアウト処理
export const logOutSubmit = async () => {
  let result = true;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/Logout';
    const token = getAccessToken();
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then((response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return response;
        }
      })
      .then((data) => {
        if (data && data?.status === StatusCodes.OK) {
          // localStorage.clear(); // セクション情報をクリック
          localStorage?.removeItem('iQxWeb_LoginUser');
          localStorage?.removeItem('iQxWeb_AccessToken');
          window.location.replace(loginPath); // ログインへ遷移
        } else if (data && data?.status !== StatusCodes.OK) {
          error(data);
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return result;
};

// ログインユーザーの情報を取得する
export const getCurrentUserInfo = async () => {
  let ret = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/CurrentUserInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return await response?.json();
        }
      })
      .then((data) => {
        if (data) {
          // セクションにログインユーザー情報を保持する
          // Access value associated with the key
          localStorage.setItem('iQxWeb_LoginUser', encodeURI(JSON.stringify(data)));
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage().E004;
        }
      })
      .catch((error) => {
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    ret = error.message;
  }
  return ret;
};

// お客様情報の取得
export const getClientInfo = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let clientsData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client/GetClients';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          clientsData.data = data?.data;
          clientsData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return clientsData;
};

// 担当者情報をDBから取得する
export const getStaffInfo = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let personInChargeData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/GetStaff';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          personInChargeData.data = data?.data;
          personInChargeData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return personInChargeData;
};

// 案件状態情報をDBから取得する
export const getEstimateStatusData = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let projectStatusData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus/GetEstimateStatus';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          projectStatusData.data = data?.data;
          projectStatusData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return projectStatusData;
};

// 購入品情報をDBから取得する
export const getPurchaseInfo = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let purchaseData = {
    data: [],
    totalCount: 0,
  };

  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/GetPurchases';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })

      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          purchaseData.data = data?.data;
          purchaseData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseData;
};

// 購入品種別情報をDBから取得する
export const getPurchaseCategoryInfo = async () => {
  let purchaseCategoryData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          purchaseCategoryData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseCategoryData;
};

// 購入品種別より所属している購入品があるかどうかのチェック
export const isExistPurchaseInfo = async (id) => {
  let retMessage = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory/CheckPurchase/' + id;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          // 購入品がある場合、
          retMessage = ErrorMessage()[data?.messageCode];
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return retMessage;
};

// 材質区分マスタ情報をDBから取得する
export const getMaterialTypeInfo = async () => {
  let materialTypeData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialType';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialTypeData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialTypeData;
};

// 板金子部品材質マスタ情報をDBから取得する
export const getMaterialTypeIQ3Info = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let materialTypeIq3Data = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3/GetMaterialTypeIq3s';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialTypeIq3Data.data = data?.data;
          materialTypeIq3Data.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialTypeIq3Data;
};

// 板金子部品材料サイズマスタ情報をDBから取得する
export const getMaterialSizeIQ3Info = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let materialSizeIq3Data = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3/GetMaterialSizeIq3';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialSizeIq3Data.data = data?.data;
          materialSizeIq3Data.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialSizeIq3Data;
};

// 材料表面保護マスタ情報をDBから取得する
export const getMaterialSurfaceIQ3Info = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let materialSurfaceIq3Data = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3/GetMaterialSurfaceIq3';
    const otherParam = {
      method: 'POSt',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialSurfaceIq3Data.data = data?.data;
          materialSurfaceIq3Data.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialSurfaceIq3Data;
};

// 板金子部品材料マスタ情報をDBから取得する
export const getMaterialIQ3Info = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let materialIq3Data = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3/GetMaterialIq3';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialIq3Data.data = data?.data;
          materialIq3Data.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialIq3Data;
};

// 工程マスタ情報をDBから取得する
export const getProcessInfo = async () => {
  let processData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Process';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          processData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return processData;
};

// 購入品引当マスタ情報をDBから取得する
export const getPurchaseAllocationInfo = async () => {
  let purchaseAllocationData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseAllocation';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          purchaseAllocationData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseAllocationData;
};

// 装置マスタ情報をDBから取得する IQX_WEBEST-325 パラメータ画面表示速度調査
export const getDeviceInfo = async (maxRecords, pageIndex, currentClass, ascSortFlg, sortNm, filterData) => {
  let devicesData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Device/GetDevices';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        class: currentClass,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          devicesData.data = data?.data;
          devicesData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return devicesData;
};

// 装置IDにより装置マスタ情報をDBから取得する IQX_WEBEST-325 パラメータ画面表示速度調査
export const getDeviceInfoById = async (deviceId) => {
  let devicesData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Device/GetDevicesById/' + deviceId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          devicesData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return devicesData;
};

// お客様情報の取得
export const getCompanyInfo = async () => {
  let companysData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          companysData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return companysData;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return companysData;
      });
  } catch (e) {
    error(e.message);
    return companysData;
  }
  return companysData;
};

// 工程パターンマスタ情報をDBから取得する
export const getProcessPatternInfo = async (maxRecords, pageIndex, currentClass, ascSortFlg, sortNm) => {
  let processPatternData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern/GetProcessPatterns';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        class: currentClass,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
      }),
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }

        if (data && data?.statusCode === StatusCodes.OK) {
          processPatternData.data = data?.data;
          processPatternData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return processPatternData;
};

// 工程パターンマスタ情報をDBから取得する
export const getProcessPatternInfoById = async (patternId) => {
  let processPatternData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern/' + patternId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }

        if (data && data?.statusCode === StatusCodes.OK) {
          processPatternData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return processPatternData;
};

// 見積情報をDBから取得する
export const getEstimateProductInfo = async (
  current,
  pageSize,
  ascSortFlg,
  sortNm,
  filterData,
  filterSettingData,
  customerUsedType,
  epCustomInfoFlg
) => {
  let esProductDataList = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetEstimateProducts'; // IQX_WEBEST-240
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        startIndex: current,
        dataRange: pageSize,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
        filterSettingData: filterSettingData,
        customerUsedType: customerUsedType,
        epCustomInfoFlg: epCustomInfoFlg,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esProductDataList.data = data?.data;
          esProductDataList.totalCount = data?.totalCount;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esProductDataList;
};

// 親部品のIdにより、親部品・板金部品の見積もり情報をDBから取得する(IQX_WEBEST-5)
export const getEstimateInfoById = async (estimateProductId) => {
  let esData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/' + estimateProductId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esData;
};

// 親部品のIdにより生産管理連携用CSV出力に必要とする情報だけ取得する（IQX_WEBEST-364 帳票出力の性能改善）
export const getSpecifiedEstimateInfoById = async (estimateProductId) => {
  let esData = [];
  try {
    const Url =
      process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetSpecifiedEstimateInfoById/' + estimateProductId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          return;
        }
      })
      .catch((e) => {
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esData;
};

// 親部品のIdにより個別帳票項目出力に必要とする情報だけ取得する（IQX_WEBEST-364 帳票出力の性能改善）
export const getSpecifiedEstimateCalcParametersById = async (estimateProductId) => {
  let esData = [];
  try {
    const Url =
      process.env.REACT_APP_BACKEND_API_URL +
      'EstimateProduct/GetSpecifiedEstimateCalcParametersById/' +
      estimateProductId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          return;
        }
      })
      .catch((e) => {
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esData;
};

// 親部品見積情報をDBに新規登録する
export const createEstimateProductInfo = async (createEstimateData, isShowCompleteMessage = true) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        esProductInfo: createEstimateData.estimateProducts,
        esIq3Info: createEstimateData.esIq3Info,
        epCustomCostInfo: createEstimateData.epCustomCosts,
        epCustomLabelInfo: createEstimateData.epCustomLabels,
        epCustomMemoInfo: createEstimateData.epCustomMemos,
        epCustomDateInfo: createEstimateData.epCustomDates,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
          if (isShowCompleteMessage) complete('データ保存は正常に完了しました。');
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ保存に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 親部品見積情報をDBに更新する
export const updateEstimateProductInfo = async (
  updateEstimateData,
  isShowCompleteMessage = true,
  isEstimateMode = true
) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        isEstimateMode: isEstimateMode,
        esProductInfo: updateEstimateData.estimateProducts,
        esIq3Info: updateEstimateData.esIq3Info,
        epCustomCostInfo: updateEstimateData.epCustomCosts,
        epCustomLabelInfo: updateEstimateData.epCustomLabels,
        epCustomMemoInfo: updateEstimateData.epCustomMemos,
        epCustomDateInfo: updateEstimateData.epCustomDates,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
          if (isShowCompleteMessage) complete('データ更新は正常に完了しました。');
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 工程パターンマスタ情報をDBに新規登録する
export const createProcessPatternData = async (createData) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify(createData),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ保存に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 工程パターンマスタ情報をDBに更新する
export const updateProcessPatternData = async (updateData) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: updateData.id,
        name: updateData.name,
        details: updateData.details,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 工程パターン情報をDBに一括で更新する
export const bulkEditProcessPatternData = async (bulkEditSelectedRows) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern/BulkEditProcessPattern';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        bulkEditSelectedRows: bulkEditSelectedRows,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// ユーザー情報の取得
export const getUserInfo = async () => {
  let userData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'User';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          userData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return userData;
};

// ユーザー設定の情報を取得する
export const getExistUserSetting = async () => {
  let existUserSetting = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          existUserSetting = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return existUserSetting;
};

export const getFileList = async () => {
  var transformedFilesOption = [];
  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}CompanySetting/getFileList`;
    const accessToken = getAccessToken();
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    });

    if (response?.data && response?.status === StatusCodes.OK) {
      var uploadedFiles = response?.data;
      if (uploadedFiles?.length > 0) {
        transformedFilesOption = uploadedFiles?.map((fileName, index) => ({
          id: index + 1,
          value: uploadedFiles[index],
          label: uploadedFiles[index],
          fileId: uploadedFiles[index]?.replace(/\.[^/.]+$/, ''), // Removes file extension
        }));
      } else {
        transformedFilesOption.push(
          uploadedFiles?.map((fileName, index) => ({
            id: index + 1,
            value: '',
            label: '',
            fileId: '',
          }))
        );
      }
      return transformedFilesOption;
    } else if (
      response?.data &&
      (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT)
    ) {
      authorizeError(ErrorMessage().E006);
      throw new Error('Unauthorized or Conflict'); // Throw an error to indicate unauthorized or conflict
    } else if (response?.data && response?.status !== StatusCodes.OK) {
      error(ErrorMessage()[response?.data?.messageCode]);
      throw new Error('Request failed with an error status'); // Throw an error for other error statuses
    }
  } catch (internal_err) {
    error(internal_err.message);
    throw internal_err; // Re-throw the error to propagate it to the caller
  }
};

//設定済み会社データ取得
export const getExistCompanySetting = async () => {
  let existCompanySetting = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          existCompanySetting = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return existCompanySetting;
};

//個別帳票とリスト帳票分別
export const getExcelDatabyUseType = (existData) => {
  if (existData?.length > 0) {
    const dataByUseType0 = [];
    const dataByUseType1 = [];

    existData?.forEach((item) => {
      if (item.useType === 0) {
        dataByUseType0.push(item);
      } else if (item.useType === 1) {
        dataByUseType1.push(item);
      }
    });

    return {
      0: dataByUseType0,
      1: dataByUseType1,
    };
  }

  return {
    useType0: [],
    useType1: [],
  };
};

//Severからformatファイル取得
export const getS3FileContent = async (fileId) => {
  let stream;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting/getS3FileContent';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        companySettingType: '0',
        Langs: {},
        Envs: {},
        InitNames: {},
        ExcelFormats: [],
        CSVFormats: {},
        PrintOutputs: [],
        CSVOutputs: {},
        settingInfo: { isUsed: true, remarks: 'testput', sortNum: 0 },
        downloadFormatId: fileId,
      }),
    };

    const response = await fetch(Url, otherParam);

    if (!response?.ok) {
      // Handle HTTP error responses
      if (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT) {
        authorizeError(ErrorMessage().E006);
      } else {
        if (response?.messageCode) {
          error(ErrorMessage()[response?.messageCode]);
        } else {
          let errors = '';
          Object.entries(response?.errors)?.map(([key, data]) => {
            errors += data;
          });
          error('データ取得に失敗しました。' + errors);
        }
      }
      return;
    }

    const arrayBuffer = await response?.arrayBuffer();
    stream = new Uint8Array(arrayBuffer);

    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error(e.message);
    return;
  }
  return stream;
};

//S3上のファイルダウンロード // IQX_WEBEST-273 帳票のExcelをダウンロードしたい
export const downloadS3FileContent = async (fileNm) => {
  let stream;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting/downloadS3FileContent';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        fileNm: fileNm,
      }),
    };

    const response = await fetch(Url, otherParam);

    if (!response?.ok) {
      // Handle HTTP error responses
      if (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT) {
        authorizeError(ErrorMessage().E006);
      } else {
        error('該当ファイルが' + ErrorMessage().E022);
      }
      return;
    }
    const arrayBuffer = await response?.arrayBuffer();
    stream = new Uint8Array(arrayBuffer);
    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error('ファイルダウンロードに失敗しました。' + e.message);
    return;
  }
  return stream;
};

//S3上のファイル削除 // IQX_WEBEST-274 帳票のExcelを削除したい
export const deleteS3FileContent = async (fileNm) => {
  let result = true;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting/deleteS3FileContent';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        fileNm: fileNm,
      }),
    };

    const response = await fetch(Url, otherParam);

    if (!response?.ok) {
      // Handle HTTP error responses
      if (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT) {
        authorizeError(ErrorMessage().E006);
      } else {
        if (response?.status === StatusCodes.BAD_REQUEST) {
          error(ErrorMessage().E023);
        } else if (response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          error('該当ファイルが' + ErrorMessage().E022);
        }
      }
      result = false;
      return;
    }
    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error('データ削除に失敗しました。' + e.message);
    result = false;
    return;
  }
  return result;
};

// 見積情報の新旧パラを判断する
export const getIsEstimateNewParam = async (estimateData) => {
  let isNewParam = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetIsEstimateNewParam';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        esProductInfo: estimateData.estimateProducts,
        esIq3Info: estimateData.esIq3Info,
        epCustomCostInfo: estimateData.epCustomCosts,
        epCustomLabelInfo: estimateData.epCustomLabels,
        epCustomMemoInfo: estimateData.epCustomMemos,
        epCustomDateInfo: estimateData.epCustomDates,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isNewParam = data?.isNewParam;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isNewParam;
};

// 親部品のIdにより、板金部品の見積もり情報をDBから取得する
export const getEstimateIq3Info = async (estimateProductId) => {
  let esIq3Data = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateIq3/' + estimateProductId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esIq3Data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esIq3Data;
};

// 見積情報をDBから取得する
export const getAllIQ3EstimateInfo = async () => {
  let esProductData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateIq3';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esProductData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esProductData;
};

// お客様情報の取得
export const GetParametersByCompId = async () => {
  let parameters = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetParametersByCompId';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          parameters = data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return parameters;
};

// Card情報をDBから取得する
export const getCardInfo = async (
  maxRecords,
  pageIndex,
  customer,
  assemblyName,
  drawingNo,
  modifier,
  minDate,
  maxDate,
  dataType
) => {
  let cardData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Card';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        customer: customer,
        assemblyName: assemblyName,
        drawingNo: drawingNo,
        modifier: modifier,
        dataType: dataType,
        dateFrom: minDate ? new Date(minDate) : null,
        dateTo: maxDate ? new Date(maxDate) : null,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cardData.data = data?.data;
          cardData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cardData;
};

// Card画像を取得する
export const getCardDetailImg = async (cadDataDetailId) => {
  let imgData;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad2d/CadData/Thumb/' + cadDataDetailId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    const response = await fetch(Url, otherParam);
    if (!response?.ok) {
      return;
    }

    imgData = response?.blob();

    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error(e.message);
    return;
  }
  return imgData;
};

// Card画像を取得する(IQX_WEBEST-207)
export const getEstimateCardDetailImg = async (esIq3Id) => {
  let imgData;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Card/' + esIq3Id;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    const response = await fetch(Url, otherParam);
    if (!response?.ok) {
      return;
    }

    imgData = response?.blob();

    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error(e.message);
    return;
  }
  return imgData;
};

// 同一図番があるか存在チェックする
export const getIsExistSameEstimate = async (estimateProduct, type) => {
  let isExist = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetIsExistSameEstimate';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        type: type,
        id: estimateProduct?.id,
        drawingNo: estimateProduct?.drawingNo,
        name: estimateProduct?.name,
        estimateNo: estimateProduct?.estimateNo,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExist = data?.isExistEstimate;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExist;
};

// ログインユーザー情報を更新する
export const updateUserSetting = async (userSetting, selectedItem) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        userSettingType: selectedItem,
        Info: userSetting.users,
        Langs: userSetting.languages,
        Msgs: userSetting.massages,
        SettingInfo: userSetting.info,
        ParamUpdateFlgInfos: userSetting.parametersUpdateFlgInfos,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data[0];
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 親部品見積情報をDBにまとめて更新する
export const bulkEditEstimateProductInfos = async (
  current,
  pageSize,
  ascSortFlg,
  sortNm,
  filterData,
  filterSettingData,
  customerUsedType,
  epCustomInfoFlg,
  bulkEditSelectedRows,
  updateEstimateData,
  isCurParamBulkEdit,
  bulkEditCalParameters,
  isShowCompleteMessage = true
) => {
  let esProductDataList = {
    data: [],
    totalCount: 0,
  };
  try {
    let bulkRditRequestInfo = {
      startIndex: current,
      dataRange: pageSize,
      sortOrder: ascSortFlg,
      sortNm: sortNm,
      filterData: filterData,
      filterSettingData: filterSettingData,
      customerUsedType: customerUsedType,
      epCustomInfoFlg: epCustomInfoFlg,
    };
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/PutEstimateProductInfos';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        bulkEditSelectedRows: bulkEditSelectedRows,
        esProductInfo: updateEstimateData.estimateProducts,
        isCurParamBulkEdit: isCurParamBulkEdit,
        bulkEditRequestInfo: bulkRditRequestInfo,
        calcParameters: bulkEditCalParameters,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esProductDataList.data = data?.data;
          esProductDataList.totalCount = data?.totalCount;
          if (isShowCompleteMessage) complete('データ更新は正常に完了しました。');
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esProductDataList;
};

// CSV取引先情報をDBに新規登録する
export const createClientCSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        clientsRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV担当者情報をDBに新規登録する
export const createPersonInChargeCSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        staffsRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV購入品情報をDBに新規登録する
export const createPurchaseCSV = async (createDatas) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        purchasesRequest: createDatas,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV係数情報をDBに新規登録する
export const createCoeffCSV = async (createDatas) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        companyCoefsRequest: createDatas,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV追加項目情報をDBに新規登録する
export const createAdditionalItemCSV = async (createDatas) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/CreateCSVRecordsAdditional';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        companyAdditionalsRequest: createDatas,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV案件情報をDBに新規登録する
export const createEstimateStatusCSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        estimateStatusRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV材質区分情報をDBに新規登録する
export const createMaterialTypeCSV = async (createDatas) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialType/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialTypesRequest: createDatas,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV自動引当情報をDBに新規登録する
export const createPurchaseAllocationCSV = async (createDatas) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseAllocation/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        purchaseAllocationsRequest: createDatas,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV材質名称情報をDBに新規登録する
export const createMaterialTypeIq3CSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialTypeIq3sRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV材料名称情報をDBに新規登録する
export const createMaterialIq3CSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialIq3sRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV材料表面情報をDBに新規登録する
export const createMaterialSurfaceIq3CSV = async (
  createDatas,
  maxRecords,
  pageIndex,
  ascSortFlg,
  sortNm,
  filterData
) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialSurfaceIq3sRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// CSV材料サイズ情報をDBに新規登録する
export const createMaterialSizeIq3CSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialSizeIq3sRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 工程マスターIDに該当する装置マスタ情報を取得
export const GetDevicesByProcessId = async (processId) => {
  let datas = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Device/GetDevicesByProcessId/' + processId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          datas = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return datas;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return datas;
      });
  } catch (e) {
    error(e.message);
    return datas;
  }
  return datas;
};

// お客様のコードと枝番の重複チェック
export const getIsExistCodeBranchNum = async (id, code, branchNum) => {
  let isExistCodeBranchNum = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client/GetIsExistCodeBranchNum';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        code: code,
        branchNum: branchNum,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeBranchNum = data?.isExistCodeBranchNum;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeBranchNum;
};

// 担当者のコードと名前の重複チェック
export const getIsExistCodeName = async (id, code, name) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff/GetIsExistCodeName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        code: code,
        name: name,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistCodeName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// 案件状態のコードと名前の重複チェック
export const getIsExistCodeProjectStatusName = async (id, code, name) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus/GetIsExistCodeName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        code: code,
        name: name,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistCodeName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// 材質名称の重複チェック
export const getIsExistMaterialClassName = async (id, name) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3/GetIsExistMaterialClassName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        name: name,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistCodeName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// 材料名称の重複チェック
export const getIsExistMaterialName = async (id, name) => {
  let isExistName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3/GetIsExistMaterialName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        name: name,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistName = data?.isExistName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistName;
};

// 材料表面の材料表面保護名と材質名称の重複チェック
export const getIsExistMaterialSurfaceName = async (id, name, materialClassName) => {
  let isExistName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3/GetIsExistMaterialSurfaceName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        name: name,
        materialClassName: materialClassName,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistName = data?.isExistName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistName;
};

// 材料サイズ名称の重複チェック
export const getIsExistMaterialSizeName = async (id, name) => {
  let isExistName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3/GetIsExistMaterialSizeName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        name: name,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistName = data?.isExistName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistName;
};

// 係数のコードと名称の重複チェック
export const getIsExistCoeffCodeName = async (id, code, name, tableNo) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/GetIsExistCoeffCodeName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        code: code,
        name: name,
        tableNo: tableNo,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistCodeName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// 追加項目のコードと名称の重複チェック
export const getIsExistAdditionItemCodeName = async (id, code, name, tableNo) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/GetIsExistAdditionItemCodeName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        code: code,
        name: name,
        tableNo: tableNo,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistCodeName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// 鋼材区分情報をDBから取得する
export const getSteelShapeInfo = async () => {
  let steelShapeData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelShape/Get';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelShapeData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelShapeData;
};

// 鋼材区分情報をDBに更新する
export const updateSteelShapeInfo = async (steelShapes, isShowCompleteMessage = true) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelShape/Put';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        steelShapes: steelShapes,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 材料計算情報をDBから取得する
export const getSteelFixedSizeInfo = async (maxRecords, pageIndex, ascSortFlg, sortNm) => {
  let steelFixedSizeData = {
    data: [],
    mateiralTypes: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelFixedSize/GetSteelFixedSizes';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelFixedSizeData.data = data?.data;
          steelFixedSizeData.mateiralTypes = data?.materialTypes;
          steelFixedSizeData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelFixedSizeData;
};

// 材料計算情報の登録
export const createSteelFixedSizeInfo = async (steelFixedSizeInfo) => {
  let steelFixedSizeData = {
    data: [],
    mateiralTypes: [],
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelFixedSize/Post';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: steelFixedSizeInfo.id,
        name: steelFixedSizeInfo.name,
        length: steelFixedSizeInfo.length,
        steelFixedSizeMatTypes: steelFixedSizeInfo.steelFixedSizeMatTypes,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelFixedSizeData.data = data?.data;
          steelFixedSizeData.mateiralTypes = data?.materialTypes;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ登録に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelFixedSizeData;
};

// 材料計算情報の編集
export const updateSteelFixedSizeInfo = async (steelFixedSizeInfo) => {
  let steelFixedSizeData = {
    data: [],
    mateiralTypes: [],
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelFixedSize/Put';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: steelFixedSizeInfo.id,
        name: steelFixedSizeInfo.name,
        length: steelFixedSizeInfo.length,
        steelFixedSizeMatTypes: steelFixedSizeInfo.steelFixedSizeMatTypes,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelFixedSizeData.data = data?.data;
          steelFixedSizeData.mateiralTypes = data?.materialTypes;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelFixedSizeData;
};

// 材料計算情報の削除
export const deleteSteelFixedSizeInfo = async (steelFixedSizeId) => {
  let steelFixedSizeData = {
    data: [],
    mateiralTypes: [],
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelFixedSize/Delete/' + steelFixedSizeId;
    const otherParam = {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelFixedSizeData.data = data?.data;
          steelFixedSizeData.mateiralTypes = data?.materialTypes;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ削除に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelFixedSizeData;
};

// 形鋼材質情報をDBから取得する
export const getSteelMaterialNameInfo = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let steelMaterialNameData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/Get';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialNameData.data = data?.data;
          steelMaterialNameData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialNameData;
};

// 形鋼材質情報の登録
export const createSteelMaterialNameInfo = async (steelMaterialNameInfo) => {
  let steelMaterialNameData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/Post';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: steelMaterialNameInfo.id,
        code: steelMaterialNameInfo.code,
        name: steelMaterialNameInfo.name,
        materialTypeId: steelMaterialNameInfo.materialTypeId,
        info: steelMaterialNameInfo.info,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialNameData.data = data?.data;
          steelMaterialNameData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            if (data?.errors) {
              Object.entries(data?.errors)?.map(([key, data]) => {
                errors += data;
              });
            } else if (data?.validationErrors) {
              Object.entries(data?.validationErrors)?.map(([key, data]) => {
                let errorItem = data?.propertyName === 'Name' ? '材質名称' : data?.propertyName;
                errors += ErrorMessage(errorItem)[data?.errorCode];
              });
            }
            error('データ登録に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialNameData;
};

// 形鋼材質情報の編集
export const updateSteelMaterialNameInfo = async (steelMaterialNameInfo) => {
  let steelMaterialNameData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/Put';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: steelMaterialNameInfo.id,
        code: steelMaterialNameInfo.code,
        name: steelMaterialNameInfo.name,
        materialTypeId: steelMaterialNameInfo.materialTypeId,
        info: steelMaterialNameInfo.info,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialNameData.data = data?.data;
          steelMaterialNameData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialNameData;
};

// 形鋼材質情報の削除
export const deleteSteelMaterialNameInfo = async (steelMaterialNameId) => {
  let steelMaterialNameData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/Delete/' + steelMaterialNameId;
    const otherParam = {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialNameData.data = data?.data;
          steelMaterialNameData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ削除に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialNameData;
};

// 形鋼材質区分マスタ情報をDBから取得する
export const getIQ5MaterialTypeInfo = async () => {
  let materialTypeData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialType/GetBeamsMaterialType';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialTypeData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialTypeData;
};

// 形鋼材質のソート情報をDBに更新する
export const updateSteelMaterialNameSortInfo = async (steelMaterialNameInfos, isShowCompleteMessage = true) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/PutSortData';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialTypeIq5s: steelMaterialNameInfos,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 材料明細情報をDBから取得する
export const getSteelMaterialInfo = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let steelMaterialData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq5/Get';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialData.data = data?.data;
          steelMaterialData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialData;
};

// 材料明細情報の登録
export const createSteelMaterialInfo = async (steelMaterialInfo) => {
  let steelMaterialData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq5/Post';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: steelMaterialInfo.id,
        code: steelMaterialInfo.code,
        name: steelMaterialInfo.name,
        materialTypeIq5Id: steelMaterialInfo.materialTypeIq5Id,
        steelShapeId: steelMaterialInfo.steelShapeId,
        info: steelMaterialInfo.info,
        created: steelMaterialInfo.created,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialData.data = data?.data;
          steelMaterialData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            if (data?.errors) {
              Object.entries(data?.errors)?.map(([key, data]) => {
                errors += data;
              });
            } else if (data?.validationErrors) {
              Object.entries(data?.validationErrors)?.map(([key, data]) => {
                let errorItem = data?.propertyName === 'Name' ? '材質名称' : data?.propertyName;
                errors += ErrorMessage(errorItem)[data?.errorCode];
              });
            }
            error('データ登録に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialData;
};

// 材料明細情報の編集
export const updateSteelMaterialInfo = async (steelMaterialInfo) => {
  let steelMaterialData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq5/Put';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: steelMaterialInfo.id,
        code: steelMaterialInfo.code,
        name: steelMaterialInfo.name,
        materialTypeIq5Id: steelMaterialInfo.materialTypeIq5Id,
        steelShapeId: steelMaterialInfo.steelShapeId,
        info: steelMaterialInfo.info,
        created: steelMaterialInfo.created,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialData.data = data?.data;
          steelMaterialData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialData;
};

// 材料明細情報の削除
export const deleteSteelMaterialInfo = async (steelMaterialId) => {
  let steelMaterialData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq5/Delete/' + steelMaterialId;
    const otherParam = {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelMaterialData.data = data?.data;
          steelMaterialData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ削除に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelMaterialData;
};

// CSV材料明細情報をDBに新規登録する
export const createSteelMaterialIq5CSV = async (createDatas, maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq5/Post/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialIq5sRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 材料明細情報の形鋼名称、材質名称と材料名称の重複チェック
export const getIsExistMaterialIq5Name = async (materialIq5Id, steelShapeId, materialTypeIq5Id, materialIq5Nm) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq5/Post/getIsExistMaterialIq5Name';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: materialIq5Id,
        steelShapeId: steelShapeId,
        materialTypeIq5Id: materialTypeIq5Id,
        name: materialIq5Nm,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// CSV形鋼の材料名称をDBに新規登録する
export const createSteelMaterialNameCSV = async (
  createDatas,
  maxRecords,
  pageIndex,
  ascSortFlg,
  sortNm,
  filterData
) => {
  let ret = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/Post/CreateCSVRecords';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        materialTypeIq5sRequest: createDatas,
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret.data = data.data;
          ret.totalCount = data.totalRecords;
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 形鋼の材質名称の重複チェック
export const getIsExistSteelMaterialName = async (id, name) => {
  let isExistCodeName = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq5/Post/GetIsExistSteelMaterialName';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: id,
        name: name,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isExistCodeName = data?.isExistName;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isExistCodeName;
};

// 採用単価初期設定情報をDBから取得する
export const getSteelUnitUseInfo = async () => {
  let steelUnitUseData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelUnitUse';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelUnitUseData.data = data?.data;
          steelUnitUseData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelUnitUseData;
};

// 採用単価初期設定情報の編集
export const updateSteelUnitUseInfo = async (steelUnitUseLstUp) => {
  let steelUnitUseData = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'SteelUnitUse';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        steelUnitUses: steelUnitUseLstUp,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          steelUnitUseData.data = data?.data;
          steelUnitUseData.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ更新に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return steelUnitUseData;
};
