/**
 * クラス名：形鋼の材料明細の詳細画面
 * 説明：形鋼の材料明細の詳細画面です。
 * 作成者：ナンス
 * 作成日：2024/10/10
 * バージョン：1.0
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Select, Input, Image, DatePicker } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import {
  formValidatorMode,
  toDecimal,
  regexExp,
  toHalfWidth,
  formatDate,
  defaultValue,
  isDecimal,
  JPYs,
  getSectionalarea,
  getPerimeter,
} from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';
import { getIsExistMaterialIq5Name } from '../../../common/CommonAPI';
import CurrencyInput from 'react-currency-input-field';

dayjs.extend(customParseFormat);

const SteelMaterialDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [steelMaterialClassInfo, setSteelMaterialClassInfo] = useState([]);

  const inputItemRef = {};
  const editMode = props.editMode;

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let detailData = props.selectedData;
      detailData.info.gravity = toDecimal(detailData.info.gravity, 2);
      detailData.info.lossCoeff = toDecimal(detailData.info.lossCoeff, 2);
      let selectedData = JSON.parse(JSON.stringify({ ...detailData }));
      setOriginData({ ...props.selectedData });
      setSelectedRowData(selectedData);
      setIsNoData(false);
      if (props.steelMaterialClassList && props.steelMaterialClassList?.length > 0) {
        let steelMaterialClassInfo = props.steelMaterialClassList?.filter(
          (item) => item.id === props.selectedData?.steelShapeId
        );
        setSteelMaterialClassInfo(steelMaterialClassInfo?.length > 0 ? steelMaterialClassInfo : []);
      }
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData, props.steelMaterialClassList]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData?.info[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData, info: { ...prevData.info } };
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = async (inputItems, procMode, isDetail, materialClassNameLst) => {
    let ret = true;
    let message = [];

    // 名称
    let input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    let materialIq5Nm = input?.value;
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材料名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 形鋼名称
    if (isDetail) {
      input = document.getElementsByName('steelShapeId-U')?.[0];
    } else {
      input = document.getElementsByName('steelShapeId-CC')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    let steelShapeNm = input?.innerText;
    if (input?.innerText === defaultValue.steelMaterialClass && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('鋼材名称').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 材質名称
    if (isDetail) {
      input = document.getElementsByName('materialTypeIq5Id-U')?.[0];
    } else {
      input = document.getElementsByName('materialTypeIq5Id-CC')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    let materialTypeIq5Nm = input?.innerText;
    if (input?.innerText === defaultValue.materialType && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質名称').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // T1
    input = inputItems['t1']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('T1').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }
    // T2
    input = inputItems['t2']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('T2').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // A
    input = inputItems['a']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('A').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // B
    input = inputItems['b']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('B').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // C
    input = inputItems['c']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('C').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 単価重量
    input = inputItems['kgPerM']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('単価重量').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    let materialIq5Id = procMode === formValidatorMode.Create ? 0 : selectedRowData.id;
    let materialTypeIq5 = props?.materialTypeIq5List?.filter((item) => item.name === materialTypeIq5Nm);
    let materialTypeIq5Id = materialTypeIq5?.length > 0 ? materialTypeIq5?.[0]?.id : 0;
    let steelShape = props?.steelMaterialClassList?.filter((item) => item.name === steelShapeNm);
    let steelShapeId = steelShape?.length > 0 ? steelShape?.[0]?.id : 0;
    if (materialTypeIq5Nm != '' && steelShapeNm != '' && materialIq5Nm !== '') {
      let isExistName = await getIsExistMaterialIq5Name(materialIq5Id, steelShapeId, materialTypeIq5Id, materialIq5Nm);
      if (isExistName) {
        message.push(<li>{ErrorMessage('鋼材名称、材質名称と材料名称').E020}</li>);
        input = inputItems['name']?.input;
        input?.classList?.remove('input-error');
        input?.classList.add('input-error');
        ret = false;
      }
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = async (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      var materialClassNameLst = props.materialClassNameData?.filter((item) => item.id !== selectedRowData.id);
      if (!(await formValidation(inputItemRef, formValidatorMode.Edit, true, materialClassNameLst))) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };
      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      updateData.created = dayjs(updateData.created).format('YYYY-MM-DD');
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData({ ...props.selectedData });
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true, isChangeEvent) => {
    if (editMode === false) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (isCheckZenkaku) {
      if (name !== 'pricePerM' && name !== 'pricePerKg' && name !== 'kgPerM') {
        value = toDecimal(value === '' ? 0 : value, 2);
      } else if (name === 'kgPerM') {
        value = toDecimal(value === '' ? 0 : value, 3);
      }
    }
    let changeData = selectedRowData;
    let perimeter = 0;
    let sectionalarea = 0;
    let kgPerM = 0;
    if (steelMaterialClassInfo?.length > 0 && !isChangeEvent) {
      sectionalarea = getSectionalarea(steelMaterialClassInfo?.[0]?.shapeNo, selectedRowData?.info);
      perimeter = getPerimeter(
        steelMaterialClassInfo?.[0]?.shapeNo,
        selectedRowData?.info,
        props?.isInnerDiameterIncluded
      );
      let materialTypeIq5 = props?.materialTypeIq5List?.filter(
        (item) => item.id === selectedRowData?.materialTypeIq5Id
      );
      let specificGravity = materialTypeIq5?.[0]?.info.gravity;
      kgPerM = (specificGravity * sectionalarea) / 1000;
      kgPerM = kgPerM ? Math.abs(kgPerM) : 0;
      kgPerM = kgPerM?.toFixed(3);
      changeData.info['kgPerM'] = kgPerM;
      changeData.info['area'] = sectionalarea;
      changeData.info['perimeter'] = perimeter;
      setSelectedRowData((prevData) => ({
        ...prevData,
        info: changeData.info,
      }));
    }
    editDetailInput(name, value);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* 鋼材名称 */}
              <tr className="detail-tbl-row" style={{ height: 45 }}>
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">鋼材名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          if (String(originData['steelShapeId']) !== String(e)) {
                            editDetailInput('steelShapeId', e);
                            let steelMaterialClassInfo = props.steelMaterialClassList?.filter((item) => item.id === e);
                            setSteelMaterialClassInfo(steelMaterialClassInfo?.length > 0 ? steelMaterialClassInfo : []);
                            let sectionalarea =
                              steelMaterialClassInfo?.length > 0
                                ? getSectionalarea(steelMaterialClassInfo?.[0]?.shapeNo, selectedRowData?.info)
                                : 0;
                            let perimeter = getPerimeter(
                              steelMaterialClassInfo?.[0]?.shapeNo,
                              selectedRowData?.info,
                              props?.isInnerDiameterIncluded
                            );
                            let materialTypeIq5 = props?.materialTypeIq5List?.filter(
                              (item) => item.id === selectedRowData?.materialTypeIq5Id
                            );
                            let specificGravity = materialTypeIq5?.[0]?.info.gravity;
                            let kgPerM = (specificGravity * sectionalarea) / 1000;
                            kgPerM = kgPerM ? Math.abs(kgPerM) : 0;
                            kgPerM = kgPerM?.toFixed(3);
                            let infoTemp = selectedRowData;
                            infoTemp.info.kgPerM = kgPerM;
                            infoTemp.info.area = sectionalarea;
                            infoTemp.info.perimeter = perimeter;
                            setSelectedRowData((prevData) => ({
                              ...prevData,
                              info: infoTemp.info,
                            }));
                          }
                        }}
                        placeholder="鋼材名称"
                        id="steelShapeId"
                        name="steelShapeId-U"
                        value={selectedRowData?.steelShapeId}
                      >
                        {props.steelMaterialClassList?.map((item, index) => (
                          <Select.Option key={index} id={'steelShapeId' + item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <>
                      <label className="param_detail-title-label">
                        {props?.getSteelMaterialClassName(selectedRowData?.steelShapeId)}
                      </label>
                    </>
                  )}
                </td>
                <td
                  style={{
                    verticalAlign: 'middle',
                    borderLeft: '1px solid #a5a5a5',
                    width: '130px',
                    textAlign: 'center',
                  }}
                  rowSpan={2}
                >
                  <Image
                    src={'/images/SteelShape/' + steelMaterialClassInfo?.[0]?.shapeNo + '.Png'}
                    alt=""
                    width={90}
                    preview={true}
                    style={{ marginLeft: -5 }}
                  />
                </td>
              </tr>
              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          if (String(originData['materialTypeIq5Id']) !== String(e)) {
                            editDetailInput('materialTypeIq5Id', e);
                            let sectionalarea =
                              steelMaterialClassInfo?.length > 0
                                ? getSectionalarea(steelMaterialClassInfo?.[0]?.shapeNo, selectedRowData?.info)
                                : 0;
                            let perimeter = getPerimeter(
                              steelMaterialClassInfo?.[0]?.shapeNo,
                              selectedRowData?.info,
                              props?.isInnerDiameterIncluded
                            );
                            let materialTypeIq5 = props?.materialTypeIq5List?.filter((item) => item.id === e);
                            let specificGravity = materialTypeIq5?.[0]?.info.gravity;
                            let kgPerM = (specificGravity * sectionalarea) / 1000;
                            kgPerM = kgPerM ? Math.abs(kgPerM) : 0;
                            kgPerM = kgPerM?.toFixed(3);
                            let infoTemp = selectedRowData;
                            infoTemp.info.kgPerM = kgPerM;
                            infoTemp.info.area = sectionalarea;
                            infoTemp.info.perimeter = perimeter;
                            setSelectedRowData((prevData) => ({
                              ...prevData,
                              info: infoTemp.info,
                            }));
                          }
                        }}
                        placeholder="材質名称"
                        id="materialTypeIq5Id"
                        name="materialTypeIq5Id-U"
                        value={selectedRowData?.materialTypeIq5Id}
                      >
                        {props?.materialTypeIq5List?.map((item, index) => (
                          <Select.Option key={index} id={'materialTypeIq5Id' + item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">
                      {props?.getMaterialTypeIq5Name(selectedRowData?.materialTypeIq5Id)}
                    </label>
                  )}
                </td>
                <td></td>
              </tr>
              {/* 材料名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材料名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => {
                      if (String(originData['name']) !== String(e.target.value)) {
                        editDetailInput('name', e.target.value);
                      }
                    }}
                  />
                </td>
              </tr>
              {/* T1(mm) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">T1(mm)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="t1"
                    name="t1"
                    type="text"
                    data-name="name"
                    className={editMode && steelMaterialClassInfo?.[0]?.t1 ? 'input-editable' : 'input-non-editable'}
                    value={selectedRowData?.info?.t1}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                    ref={(ref) => {
                      inputItemRef['t1'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* T2(mm) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">T2(mm)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="t2"
                    name="t2"
                    type="text"
                    data-name="name"
                    className={editMode && steelMaterialClassInfo?.[0]?.t2 ? 'input-editable' : 'input-non-editable'}
                    value={selectedRowData?.info?.t2}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                    ref={(ref) => {
                      inputItemRef['t2'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* A(mm) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">A(mm)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="a"
                    name="a"
                    type="text"
                    data-name="name"
                    className={editMode && steelMaterialClassInfo?.[0]?.a ? 'input-editable' : 'input-non-editable'}
                    value={selectedRowData?.info?.a}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                    ref={(ref) => {
                      inputItemRef['a'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* B(mm) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">B(mm)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="b"
                    name="b"
                    type="text"
                    data-name="name"
                    className={editMode && steelMaterialClassInfo?.[0]?.b ? 'input-editable' : 'input-non-editable'}
                    value={selectedRowData?.info?.b}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                    ref={(ref) => {
                      inputItemRef['b'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* C(mm) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">C(mm)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="c"
                    name="c"
                    type="text"
                    data-name="name"
                    className={editMode && steelMaterialClassInfo?.[0]?.c ? 'input-editable' : 'input-non-editable'}
                    value={selectedRowData?.info?.c}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                    ref={(ref) => {
                      inputItemRef['c'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* 単価重量(kg/M) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">単価重量(kg/M)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="kgPerM"
                    name="kgPerM"
                    type="text"
                    data-name="name"
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    value={selectedRowData?.info?.kgPerM}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value, true, false)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false, true)}
                    ref={(ref) => {
                      inputItemRef['kgPerM'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* M単価 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">M単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <CurrencyInput
                    id="pricePerM"
                    name="pricePerM"
                    type="text"
                    data-name="name"
                    className={
                      editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable chargeAmount_currency'
                    }
                    value={selectedRowData?.info?.pricePerM ? selectedRowData?.info?.pricePerM : 0}
                    defaultValue={0}
                    onValueChange={(e) => {
                      setSelectedRowData((prevData) => ({
                        ...prevData,
                        info: { ...prevData.info, pricePerM: e },
                      }));
                    }}
                    prefix="¥"
                    decimalsLimit={100}
                    ref={(ref) => {
                      inputItemRef['pricePerM'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* Kg単価 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">Kg単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <CurrencyInput
                    id="pricePerKg"
                    name="pricePerKg"
                    type="text"
                    data-name="name"
                    className={
                      editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable chargeAmount_currency'
                    }
                    value={selectedRowData?.info?.pricePerKg ? selectedRowData?.info?.pricePerKg : 0}
                    defaultValue={0}
                    onValueChange={(e) => {
                      setSelectedRowData((prevData) => ({
                        ...prevData,
                        info: { ...prevData.info, pricePerKg: e },
                      }));
                    }}
                    prefix="¥"
                    decimalsLimit={100}
                    ref={(ref) => {
                      inputItemRef['pricePerKg'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  {editMode ? (
                    <DatePicker
                      name={'created'}
                      id={'created'}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      style={{ padding: 5, marginLeft: '5px !important', width: '60%' }}
                      onChange={(e, dateString) => {
                        if (dateString !== '') {
                          setSelectedRowData((prevData) => ({
                            ...prevData,
                            created: dateString,
                          }));
                        }
                      }}
                      value={selectedRowData?.created ? dayjs(selectedRowData?.created, 'YYYY/MM/DD') : ''}
                    />
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {selectedRowData?.created ? formatDate(selectedRowData?.created) : ''}
                    </label>
                  )}
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                  <Input
                    id="modified"
                    name="modified"
                    type="text"
                    data-name="name"
                    className={'input-non-editable'}
                    value={selectedRowData?.modified ? formatDate(selectedRowData?.modified) : ''}
                  />
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default SteelMaterialDetail;
