/**
 * クラス名：マインパラメータ
 * 説明：各パラメータ画面の共通操作と各当画面の親ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Form, Row, Col, Modal, Select, Image, Tooltip, Space, Input } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { RightCircleFilled, LeftCircleFilled } from '@ant-design/icons';
import { CSVLink, CSVDownload } from 'react-csv';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import '../../assets/styles/common.css';
import CustomerSupplierParamList from './common/customerSupplier/CustomerSupplierParamList';
import PersonInChargeParamList from './common/personInCharge/PersonInChargeParamList';
import PurchaseParamList from './common/purchases/PurchaseParamList';
import CoefficientParamList from './common/coefficient/CoefficientParamList';
import AdditionalItemsParamList from './common/additionalItems/AdditionalItemsParamList';
import ProjectStatusParamList from './common/projectStatus/ProjectStatusParamList';
import ProcessMasterList from './common/processMaster/ProcessMasterList';
import MaterialClassList from './common/materialClass/MaterialClassList';
import AutoReserveList from './common/autoReserve/AutoReserveList';
import ProcessPatternParamList from './parent/processPattern/ProcessPatternParamList';
import MaterialClassNameList from './iq3/materialClassName/MaterialClassNameList';
import MaterialNameList from './iq3/materialName/MaterialNameList';
import MaterialSurfaceList from './iq3/materialSurface/MaterialSurfaceList';
import MaterialSizeList from './iq3/materialSize/MaterialSizeList';
import new_icon from '../../assets/images/new_icon.png';
import child_delete_icon from '../../assets/images/child_delete_icon.png';
import export_csv_icon from '../../assets/images/export_csv_icon.png';
import import_csv_icon from '../../assets/images/import_csv_icon.png';
import edit_list from '../../assets/images/edit_list.png';
import {
  commonTaiochuModal,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
} from '../common/CommonModal';
import DevicesList from '../parameter/parent/devices/DevicesList';
import edit_info from '../../assets/images/edit_info.png';
import SteelMaterialCalculateList from './iq5/steelMaterialCalculate/SteelMaterialCalculateList';
import SteelMaterialClassList from './iq5/steelMaterialClass/SteelMaterialClassList';
import SteelMaterialNameList from './iq5/steelMaterialName/SteelMaterialNameList';
import copy from '../../assets/images/copy.png';
import SteelMaterialList from './iq5/steelMaterial/SteelMaterialList';

dayjs.extend(customParseFormat);
const iconWidth = 30;

const noAddDeletEditBtn = [
  '/home/:parent.processMaster',
  '/home/:common.processMaster',
  '/home/:iq3.processMaster',
  '/home/:iq5.processMaster',
  '/home/:common.materialClass',
  '/home/:iq5.steelMaterialClass',
];

const noAddCSVImportBtn = [
  '/home/:parent.processMaster',
  '/home/:common.processMaster',
  '/home/:iq3.processMaster',
  '/home/:iq5.processMaster',
  '/home/:common.materialClass',
  '/home/:parent.device',
  '/home/:iq3.device',
  '/home/:iq3.patternProcess',
  '/home/:parent.patternProcess',
  '/home/:iq5.device',
  '/home/:iq5.steelMaterialCalculate',
  '/home/:iq5.steelMaterialClass',
  '/home/:iq5.patternProcess',
];

const noAddCSVExportBtn = [
  '/home/:parent.processMaster',
  '/home/:common.processMaster',
  '/home/:iq3.processMaster',
  '/home/:iq5.processMaster',
  '/home/:parent.device',
  '/home/:iq3.device',
  '/home/:iq3.patternProcess',
  '/home/:parent.patternProcess',
  '/home/:iq5.device',
  '/home/:iq5.steelMaterialCalculate',
  '/home/:iq5.steelMaterialClass',
  '/home/:iq5.patternProcess',
];

const noGpEditBtn = [
  '/home/:parent.device',
  '/home/:iq3.device',
  '/home/:common.autoReserve',
  '/home/:common.coefficient',
  '/home/:common.additional',
  '/home/:iq5.device',
  '/home/:iq5.steelMaterialCalculate',
  '/home/:iq5.steelMaterialClass',
  '/home/:iq5.steelMaterialName',
  '/home/:iq5.steelMaterial',
];

const noCopyBtn = ['/home/:iq5.steelMaterialName', '/home/:iq5.steelMaterial'];

const dropTypes1 = [
  { id: 1, value: 'ALL', label: 'ALL' },
  { id: 2, value: 'material', label: '材料' },
  { id: 3, value: 'processing', label: '加工' },
  { id: 4, value: 'others', label: 'その他' },
];

const dropTypes2 = [
  { id: 1, value: 'ALL', label: 'ALL' },
  { id: 2, value: 'common', label: '共通' },
  { id: 3, value: 'iq3', label: '板金' },
  { id: 4, value: 'iq5', label: '形鋼' },
];

/* const dropParams = [
  { id: 1, value: 'customer', label: 'お客様・仕入先', keyword: ['ALL', 'others', 'common'] ,},
  { id: 2, value: 'personInCharge', label: '担当者', keyword: ['ALL', 'others', 'common'] },
  { id: 3, value: 'purchase', label: '購入品', keyword: ['ALL', 'others', 'common'] },
  { id: 4, value: 'coefficient', label: '係数', keyword: ['ALL', 'others', 'common'] },
  { id: 5, value: 'additional', label: '追加項目', keyword: ['ALL', 'others', 'common'] },
  { id: 6, value: 'project', label: '案件状態', keyword: ['ALL', 'others', 'common'] },
  { id: 7, value: 'processMaster', label: '工程マスター', keyword: ['ALL', 'processing', 'common', 'iq3'] },
  { id: 8, value: 'materialClass', label: '材質区分', keyword: ['ALL', 'material', 'common'] },
  { id: 9, value: 'autoReserve', label: '自動引当', keyword: ['ALL', 'others', 'common'] },
  { id: 10, value: 'oya_processMaster', label: '工程マスター', keyword: ['ALL', 'processing', 'common', 'iq3'] },
  { id: 11, value: 'oya_chargeProcess', label: '装置設定', keyword: ['ALL', 'processing', 'common', 'iq3'] },
  { id: 12, value: 'iq3_processMaster', label: '工程マスター', keyword: ['ALL', 'processing', 'common', 'iq3'] },
  { id: 13, value: 'iq3_chargeProcess', label: '装置設定', keyword: ['ALL', 'processing', 'common', 'iq3'] },
  { id: 14, value: 'iq3_patternProcess', label: '工程パターン', keyword: ['ALL', 'processing', 'common', 'iq3'] },

  {
    id: 15,
    value: 'iq3_materialClassName',
    label: '材質名称＊（名称毎比重、材料粗利係数）',
    keyword: ['ALL', 'material', 'iq3'],
  },
  { id: 16, value: 'iq3_materialName', label: '材料名称（個別比重、単価）', keyword: ['ALL', 'material', 'iq3'] },
  { id: 17, value: 'iq3_materialSurface', label: '材料表面', keyword: ['ALL', 'material', 'iq3'] },
  { id: 18, value: 'iq3_materialSize', label: '材料サイズ', keyword: ['ALL', 'material', 'iq3'] },
]; */

const dropParams = [
  {
    id: 1,
    value: 'customer',
    label: 'お客様・仕入先',
    keyword1: ['ALL', 'others'],
    keyword2: ['ALL', 'common'],
  },
  {
    id: 2,
    value: 'personInCharge',
    label: '担当者',
    keyword1: ['ALL', 'others'],
    keyword2: ['ALL', 'common'],
  },
  { id: 3, value: 'purchase', label: '購入品', keyword1: ['ALL', 'others'], keyword2: ['ALL', 'common'] },
  { id: 4, value: 'coefficient', label: '係数', keyword1: ['ALL', 'others'], keyword2: ['ALL', 'common'] },
  { id: 5, value: 'additional', label: '追加項目', keyword1: ['ALL', 'others'], keyword2: ['ALL', 'common'] },
  { id: 6, value: 'project', label: '案件状態', keyword1: ['ALL', 'others'], keyword2: ['ALL', 'common'] },

  {
    id: 7,
    value: 'materialClass',
    label: '材質区分',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'common'],
  },
  { id: 8, value: 'autoReserve', label: '自動引当', keyword1: ['ALL', 'others'], keyword2: ['ALL', 'common'] },
  {
    id: 9,
    value: 'processMaster',
    label: '工程マスター',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'common'],
  },
  {
    id: 10,
    value: 'oya_chargeProcess',
    label: '装置設定',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'common'],
  },
  {
    id: 11,
    value: 'oya_patternProcess',
    label: '工程パターン',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'common'],
  },
  {
    id: 12,
    value: 'iq3_processMaster',
    label: '工程マスター',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'iq3'],
  },
  {
    id: 13,
    value: 'iq3_chargeProcess',
    label: '装置設定',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'iq3'],
  },
  {
    id: 14,
    value: 'iq3_patternProcess',
    label: '工程パターン',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'iq3'],
  },

  {
    id: 15,
    value: 'iq3_materialClassName',
    label: '材質名称＊（名称毎比重、材料粗利係数）',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'iq3'],
  },
  {
    id: 16,
    value: 'iq3_materialName',
    label: '材料名称（個別比重、単価）',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'iq3'],
  },
  { id: 17, value: 'iq3_materialSurface', label: '材料表面', keyword1: ['ALL', 'material'], keyword2: ['ALL', 'iq3'] },
  { id: 18, value: 'iq3_materialSize', label: '材料サイズ', keyword1: ['ALL', 'material'], keyword2: ['ALL', 'iq3'] },
  {
    id: 19,
    value: 'iq5_processMaster',
    label: '工程マスター',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'iq5'],
  },
  {
    id: 20,
    value: 'iq5_chargeProcess',
    label: '装置設定',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'iq5'],
  },
  {
    id: 21,
    value: 'iq5_patternProcess',
    label: '工程パターン',
    keyword1: ['ALL', 'processing'],
    keyword2: ['ALL', 'iq5'],
  },
  {
    id: 22,
    value: 'iq5_steelMaterialCalculate',
    label: '材料計算',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'iq5'],
  },
  {
    id: 23,
    value: 'iq5_steelMaterialClass',
    label: '鋼材区分',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'iq5'],
  },
  {
    id: 24,
    value: 'iq5_steelMaterialName',
    label: '材質名称',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'iq5'],
  },
  {
    id: 25,
    value: 'iq5_steelMaterial',
    label: '材料明細',
    keyword1: ['ALL', 'material'],
    keyword2: ['ALL', 'iq5'],
  },
];

const dropParamCommon = [
  { id: 1, value: 'customer', label: 'お客様・仕入先' },
  { id: 2, value: 'personInCharge', label: '担当者' },
  { id: 3, value: 'purchase', label: '購入品' },
  { id: 4, value: 'coefficient', label: '係数' },
  { id: 5, value: 'additional', label: '追加項目' },
  { id: 6, value: 'project', label: '案件状態' },
  { id: 7, value: 'processMaster', label: '工程マスター' },
  { id: 8, value: 'materialClass', label: '材質区分' },
  { id: 9, value: 'autoReserve', label: '自動引当' },
];

const dropParamParent = [
  { id: 1, value: 'oya_processMaster', label: '工程マスター' },
  { id: 2, value: 'oya_chargeProcess', label: '装置設定' },
  { id: 3, value: 'oya_patternProcess', label: '工程パターン' },
];

const dropParamiQ3 = [
  { id: 1, value: 'iq3_processMaster', label: '工程マスター' },
  { id: 2, value: 'iq3_chargeProcess', label: '装置設定' },
  { id: 3, value: 'iq3_patternProcess', label: '工程パターン' },
  { id: 4, value: 'iq3_materialClassName', label: '材質名称＊（名称毎比重、材料粗利係数）' },
  { id: 5, value: 'iq3_materialName', label: '材料名称（個別比重、単価）' },
  { id: 6, value: 'iq3_materialSurface', label: '材料表面' },
  { id: 7, value: 'iq3_materialSize', label: '材料サイズ' },
];

const dropParamiQ5 = [
  { id: 1, value: 'iq5_processMaster', label: '工程マスター', typeId: 4 },
  { id: 2, value: 'iq5_chargeProcess', label: '装置設定', typeId: 4 },
  { id: 3, value: 'iq5_patternProcess', label: '工程パターン', typeId: 4 },
  { id: 4, value: 'iq5_steelMaterialCalculate', label: '材料計算', typeId: 4 },
  { id: 5, value: 'iq5_steelMaterialClass', label: '鋼材区分', typeId: 4 },
  { id: 6, value: 'iq5_steelMaterialName', label: '材質名称', typeId: 4 },
  { id: 7, value: 'iq5_steelMaterial', label: '材料明細', typeId: 4 },
];

const dropParamiQ7 = [
  { id: 1, value: '設備', label: '設備', typeId: 5 },
  { id: 2, value: '加工種類', label: '加工種類', typeId: 5 },
  { id: 3, value: '材質', label: '材質', typeId: 5 },
  { id: 4, value: '段取', label: '段取', typeId: 5 },
  { id: 5, value: '公差', label: '公差', typeId: 5 },
  { id: 6, value: '公差グループ', label: '公差グループ', typeId: 5 },
  { id: 7, value: '材料のサイズ・重量', label: '材料のサイズ・重量', typeId: 5 },
  { id: 8, value: '割増割引', label: '割増割引', typeId: 5 },
];

const MainParameter = forwardRef((props, ref) => {
  const [paramType, setParamType] = useState('');
  const [dropParamTemp, setDropParamTemp] = useState([]);
  const [paramSelected, setParamSelected] = useState('');
  // Content Resize
  const [btnShowHide, setBtnShowHide] = useState(true);
  // 開発中ポップアップ
  const [commonTaiochuPopup, setCommonTaiochuPopup] = useState(false);
  const [currentRef, setCurrentRef] = useState();
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();

  const [paramType1, setParamType1] = useState('');
  const [paramType2, setParamType2] = useState('');
  const [dropTypes2Temp, setDropTypes2Temp] = useState([]);
  const [dropParamsTemp, setDropParamsTemp] = useState([]);

  const [showConfirmMsgs, setShowConfirmMsgs] = useState([]);

  const [initialParam, setInitialParam] = useState(props.paramNm);

  // 各画面参照定義設定
  // 共通
  const customerRef = useRef();
  const personInChargeRef = useRef();
  const materialRef = useRef();
  const purchaseRef = useRef();
  const coefficientRef = useRef();
  const additionalItemsRef = useRef();
  const projectStatusRef = useRef();
  const processMasterRef = useRef();
  const materialClassRef = useRef();
  const autoReserveRef = useRef();
  // 親部品
  const processRef = useRef();
  const processPatternRef = useRef();
  // 装置
  const deviceRef = useRef();
  // iQ3
  const materialProcessRef = useRef();
  const materialQualityRef = useRef();
  const materialSizeRef = useRef();
  const deviceSettingRef = useRef();
  const timeChargeRef = useRef();
  const referencePriceRef = useRef();
  const machineSettingRef = useRef();
  const materialClassNameRef = useRef();
  const materialNameRef = useRef();
  const materialSurfaceRef = useRef();
  // iQ5
  const steelMaterialCalculateRef = useRef();
  const steelMaterialClassRef = useRef();
  const steelMaterialNameRef = useRef();
  const steelMaterialRef = useRef();

  const csvImportRef = useRef(null);
  const csvExportRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  // サイドメニューから遷移された場合、
  let paramNm = props.paramNm;

  useEffect(() => {
    if (location.pathname.indexOf('/home/:common.') > -1) {
      typesDropChange('common', true);
    } else if (location.pathname.indexOf('/home/:parent.') > -1) {
      typesDropChange('parent', true);
    } else if (location.pathname.indexOf('/home/:iq3.') > -1) {
      typesDropChange('iq3', true);
    } else if (location.pathname.indexOf('/home/:iq5.') > -1) {
      typesDropChange('iq5', true);
    }
    let paramSelected1Val = 'ALL';
    let paramSelected2Val = 'ALL';
    if (props.paramNm) setParamSelected(props.paramNm);
    if (props.paramSelected1 !== undefined && props.paramSelected1 !== '') {
      setParamType1(props.paramSelected1);
      paramSelected1Val = props.paramSelected1;
      if (paramSelected1Val == 'others')
        setDropTypes2Temp([
          { id: 1, value: 'ALL', label: 'ALL' },
          { id: 2, value: 'common', label: '共通' },
        ]);
      else setDropTypes2Temp(dropTypes2);
    } else {
      setParamType1('ALL');
    }
    if (props.paramSelected2 !== undefined && props.paramSelected2 !== '') {
      setParamType2(props.paramSelected2);
      paramSelected2Val = props.paramSelected2;
      setDropTypes2Temp(dropTypes2);
    } else {
      setParamType2('ALL');
      setDropTypes2Temp(dropTypes2);
    }
    const paramDDL = dropParams?.filter((item) => {
      const includesKeyword2 = item.keyword2.some((keyword) => paramSelected2Val?.includes(keyword));
      const includesKeyword1 = item.keyword1.some((keyword) => paramSelected1Val?.includes(keyword));
      return includesKeyword2 && includesKeyword1;
    });
    if (paramDDL) {
      setDropParamsTemp(paramDDL);
    }
    setInitialParam(props.paramNm);
  }, [props.paramNm]);

  useEffect(() => {
    if (props.userSettingInfo !== undefined && props.userSettingInfo?.length > 0) {
      let massages = props.userSettingInfo[0].massages;
      setShowConfirmMsgs(massages);
    } else {
      setShowConfirmMsgs({
        editOk: true,
        deleteEst: true,
        deletePart: true,
        editCancel: true,
        moveInEdit: true,
      });
    }
  }, [props.userSettingInfo]);

  const onFinish = (values) => {};

  // 画面切り替えする
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    currentRef.current?.updateEditMode(false);
    if (!routeChange(selectedName, true)) {
      return;
    }
    getDropParamTemp(getTypeByParam(selectedName));
  };

  // 画面切り替えしない
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // グループにより、パラメータリストを取得する
  const getDropParamTemp = (name) => {
    switch (name) {
      case 'common':
        return setDropParamTemp(dropParamCommon);
      case 'parent':
        return setDropParamTemp(dropParamParent);
      case 'iq3':
        return setDropParamTemp(dropParamiQ3);
      case 'iq5':
        return setDropParamTemp(dropParamiQ5);
      case 'iq7':
        return setDropParamTemp(dropParamiQ7);
    }
  };

  // パラメータ名により、グループ名を取得する
  const getTypeByParam = (name) => {
    if (dropParamCommon?.findIndex((item) => item.value === name) > -1) {
      return 'common';
    } else if (dropParamParent?.findIndex((item) => item.value === name) > -1) {
      return 'parent';
    } else if (dropParamiQ3?.findIndex((item) => item.value === name) > -1) {
      return 'iq3';
    } else if (dropParamiQ5?.findIndex((item) => item.value === name) > -1) {
      return 'iq5';
    } else if (dropParamiQ7?.findIndex((item) => item.value === name) > -1) {
      return 'iq7';
    }
  };

  const typesDropChange = (e, isSideMenu) => {
    getDropParamTemp(e);
    setParamType(e);
  };

  const typesDropChange1 = (e) => {
    let name;
    if (e === 'ALL') {
      name = 'customer';
      if (!paramDropChange(false, name)) return;
      // setDropParamTemp(dropParamCommon);
    } else if (e === 'material') {
      name = 'materialClass';
      if (!paramDropChange(false, name)) return;
      // setDropParamTemp(dropParamParent);
    } else if (e === 'processing') {
      name = 'processMaster';
      if (!paramDropChange(false, name)) return;
      // setDropParamTemp(dropParamiQ3);
    } else if (e === 'others') {
      name = 'customer';
      if (!paramDropChange(false, name)) return;
      // setDropParamTemp(dropParamiQ3);
    }
    getDropParamTemp(e);
    setParamType(e);

    if (e == 'others')
      setDropTypes2Temp([
        { id: 1, value: 'ALL', label: 'ALL' },
        { id: 2, value: 'common', label: '共通' },
      ]);
    else setDropTypes2Temp(dropTypes2);
    setParamType2('ALL');
    const paramDDL = dropParams?.filter((item) => {
      const includesKeyword = item.keyword1.some((keyword) => e?.includes(keyword) || dropTypes2?.includes(keyword));
      return includesKeyword;
    });
    setDropParamsTemp(paramDDL);
    props?.updateParamSelected1(e);
  };

  const typesDropChange2 = (e) => {
    let name;
    if (e === 'ALL' || e === 'common') {
      switch (paramType1) {
        case 'ALL':
          name = 'customer';
          if (!paramDropChange(false, name)) return;
          break;
        case 'material':
          name = 'materialClass';
          if (!paramDropChange(false, name)) return;
          break;
        case 'processing':
          name = 'processMaster';
          if (!paramDropChange(false, name)) return;
          break;
        case 'others':
          name = 'customer';
          if (!paramDropChange(false, name)) return;
          break;
        default:
          name = 'customer';
          if (!paramDropChange(false, name)) return;
          break;
      }
    } else if (e === 'iq3') {
      switch (paramType1) {
        case 'ALL':
          name = 'iq3_processMaster';
          if (!paramDropChange(false, name)) return;
          break;
        case 'material':
          name = 'iq3_materialClassName';
          if (!paramDropChange(false, name)) return;
          break;
        case 'processing':
          name = 'iq3_processMaster';
          if (!paramDropChange(false, name)) return;
          break;
        default:
          name = 'iq3_processMaster';
          if (!paramDropChange(false, name)) return;
          break;
      }
    } else if (e === 'iq5') {
      switch (paramType1) {
        case 'ALL':
          name = 'iq5_steelMaterialCalculate';
          if (!paramDropChange(false, name)) return;
          break;
        case 'material':
          name = 'iq3_materialClassName';
          if (!paramDropChange(false, name)) return;
          break;
        case 'processing':
          name = 'iq5_processMaster';
          if (!paramDropChange(false, name)) return;
          break;
        default:
          name = 'iq5_processMaster';
          if (!paramDropChange(false, name)) return;
          break;
      }
    }
    getDropParamTemp(e);
    setParamType(e);

    const paramDDL = dropParams?.filter((item) => {
      const includesKeyword2 = item.keyword2.some((keyword) => e?.includes(keyword));
      const includesKeyword1 = item.keyword1.some((keyword) => paramType1?.includes(keyword));
      return includesKeyword2 && includesKeyword1;
    });
    setDropParamsTemp(paramDDL);
    props?.updateParamSelected2(e);
  };

  // 編集モードの場合、画面切り替えのポップアップするようにする
  const isDetailEditing = () => {
    if (getCurrentEditMode()) {
      // 画面切り替えポップアップの表示
      //setEditModeCancelConfirm(true);
      setEditModeCancelConfirm(showConfirmMsgs?.moveInEdit);
      if (!showConfirmMsgs?.moveInEdit) {
        discardConfirmOk();
        return true;
      }
      return false;
    }
    return true;
  };

  const paramDropChange = (isSideMenu, e) => {
    // 編集モードの場合、画面切り替えのポップアップするようにする
    setSelectedName(e);
    if (!isSideMenu && !isDetailEditing()) {
      return false;
    }
    if (!routeChange(e)) return false;
    setParamType(getTypeByParam(e));
    getDropParamTemp(getTypeByParam(e));
    props?.updateCurrentParamSelected(e);
    return true;
  };

  // 画面切り替え
  const routeChange = (e) => {
    // 画面切り替え
    if (e == 'customer') {
      if (selectedName === 'processMaster') {
        setCurrentRef(processMasterRef);
      } else {
        setCurrentRef(customerRef);
        //customerRef.current.setShowEdtConfirm(showEdtConfirm);
        navigate(':common.customer');
      }
    } else if (e == 'personInCharge') {
      setCurrentRef(personInChargeRef);
      navigate(':common.personInCharge');
    } else if (e == 'purchase') {
      setCurrentRef(purchaseRef);
      navigate(':common.purchase');
    } else if (e == 'coefficient') {
      setCurrentRef(coefficientRef);
      navigate(':common.coefficient');
    } else if (e == 'additional') {
      setCurrentRef(additionalItemsRef);
      navigate(':common.additional');
    } else if (e == 'project') {
      setCurrentRef(projectStatusRef);
      navigate(':common.project');
    } else if (e == 'processMaster') {
      setCurrentRef(processMasterRef);
      navigate(':common.processMaster');
    } else if (e == 'materialClass') {
      setCurrentRef(materialClassRef);
      navigate(':common.materialClass');
    } else if (e == 'autoReserve') {
      setCurrentRef(autoReserveRef);
      navigate(':common.autoReserve');
    } else if (e == 'oya_patternProcess') {
      setCurrentRef(processPatternRef);
      navigate(':parent.patternProcess');
    } else if (e == 'oya_processMaster') {
      setCurrentRef(processMasterRef);
      navigate(':parent.processMaster');
    } else if (e == 'oya_chargeProcess') {
      setCurrentRef(deviceRef);
      navigate(':parent.device');
    } else if (e == 'oya_setupProcess') {
      // navigate(':parent.setupProcess');
      openCommonTaiochuPopup(); // 開発中
      return false;
    } else if (e == 'iq3_chargeProcess') {
      setCurrentRef(deviceRef);
      navigate(':iq3.device');
      // openCommonTaiochuPopup(); // 開発中
      // return false;
    } else if (e == 'iq3_patternProcess') {
      setCurrentRef(processPatternRef);
      navigate(':iq3.patternProcess');
    } else if (e == 'iq3_setupProcess') {
      // navigate(':iq3.setupProcess');
      openCommonTaiochuPopup(); // 開発中
      return false;
    } else if (e == 'iq3_materialClassName') {
      setCurrentRef(materialClassNameRef);
      navigate(':iq3.materialClassName');
    } else if (e == 'iq3_materialName') {
      setCurrentRef(materialNameRef);
      navigate(':iq3.materialName');
    } else if (e == 'iq3_materialSurface') {
      setCurrentRef(materialSurfaceRef);
      navigate(':iq3.materialSurface');
    } else if (e == 'iq3_materialSize') {
      setCurrentRef(materialSizeRef);
      navigate(':iq3.materialSize');
    } else if (e == 'iq3_processMaster') {
      setCurrentRef(processMasterRef);
      navigate(':iq3.processMaster');
    } else if (e == 'materialClass') {
      setCurrentRef(materialClassRef);
      navigate(':common.materialClass');
    } else if (e == 'processMaster') {
      setCurrentRef(processMasterRef);
      navigate(':common.processMaster');
    } else if (e == 'iq5_processMaster') {
      setCurrentRef(processMasterRef);
      navigate(':iq5.processMaster');
    } else if (e == 'iq5_patternProcess') {
      setCurrentRef(processPatternRef);
      navigate(':iq5.patternProcess');
    } else if (e == 'iq5_chargeProcess') {
      setCurrentRef(deviceRef);
      navigate(':iq5.device');
    } else if (e == 'iq5_steelMaterialCalculate') {
      setCurrentRef(steelMaterialCalculateRef);
      navigate(':iq5.steelMaterialCalculate');
    } else if (e == 'iq5_steelMaterialClass') {
      setCurrentRef(steelMaterialClassRef);
      navigate(':iq5.steelMaterialClass');
    } else if (e == 'iq5_steelMaterialName') {
      setCurrentRef(steelMaterialNameRef);
      navigate(':iq5.steelMaterialName');
    } else if (e == 'iq5_steelMaterial') {
      setCurrentRef(steelMaterialRef);
      navigate(':iq5.steelMaterial');
    }
    setParamSelected(e);
    return true;
  };

  const contentResizeBtn = () => {
    setBtnShowHide(false);
    if (location.pathname == '/home/:common.customer') {
      customerRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:common.personInCharge') {
      personInChargeRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:common.purchase') {
      purchaseRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:common.coefficient') {
      coefficientRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:common.additional') {
      additionalItemsRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:common.project') {
      projectStatusRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.material') {
      materialRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.material.process') {
      materialProcessRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.material.size') {
      materialSizeRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.material.quality') {
      materialQualityRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.devicesetting') {
      deviceSettingRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.timecharge') {
      timeChargeRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.process') {
      processRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.machinesetting') {
      machineSettingRef.current.setSplitPaneSize(['100%']);
    } else if (
      location.pathname == '/home/:parent.device' ||
      location.pathname == '/home/:iq3.device' ||
      location.pathname == '/home/:iq5.device'
    ) {
      deviceRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname === '/home/:common.materialClass') {
      materialClassRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:common.autoReserve') {
      autoReserveRef.current.setSplitPaneSize(['100%']);
    } else if (
      location.pathname == '/home/:common.processMaster' ||
      location.pathname == '/home/:parent.processMaster' ||
      location.pathname == '/home/:iq3.processMaster' ||
      location.pathname == '/home/:iq5.processMaster'
    ) {
      processMasterRef.current.setSplitPaneSize(['100%']);
    } else if (
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      processPatternRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      materialClassNameRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.materialName') {
      materialNameRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      materialSurfaceRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq3.materialSize') {
      materialSizeRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialCalculate') {
      steelMaterialCalculateRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialClass') {
      steelMaterialClassRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      steelMaterialNameRef.current.setSplitPaneSize(['100%']);
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      steelMaterialRef.current.setSplitPaneSize(['100%']);
    }
  };

  const contentResizeBackwardBtn = () => {
    setBtnShowHide(true);
    if (location.pathname == '/home/:common.customer') {
      customerRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:common.personInCharge') {
      personInChargeRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:common.purchase') {
      purchaseRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:common.coefficient') {
      coefficientRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:common.additional') {
      additionalItemsRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:common.project') {
      projectStatusRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq3.material') {
      materialRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq3.material.process') {
      materialProcessRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq3.material.size') {
      materialSizeRef.current.setSplitPaneSize(['50%']);
    } else if (location.pathname == '/home/:iq3.material.quality') {
      materialQualityRef.current.setSplitPaneSize(['50%']);
    } else if (location.pathname == '/home/:iq3.devicesetting') {
      deviceSettingRef.current.setSplitPaneSize(['75%']);
    } else if (location.pathname == '/home/:iq3.timecharge') {
      timeChargeRef.current.setSplitPaneSize(['75%']);
    } else if (location.pathname == '/home/:iq3.process') {
      processRef.current.setSplitPaneSize(['50%']);
    } else if (location.pathname == '/home/:iq3.machinesetting') {
      machineSettingRef.current.setSplitPaneSize(['50%']);
    } else if (
      location.pathname == '/home/:parent.device' ||
      location.pathname == '/home/:iq3.device' ||
      location.pathname == '/home/:iq5.device'
    ) {
      deviceRef.current.setSplitPaneSize(['45%']);
    } else if (location.pathname === '/home/:common.materialClass') {
      materialClassRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:common.autoReserve') {
      autoReserveRef.current.setSplitPaneSize(['60%']);
    } else if (
      location.pathname == '/home/:common.processMaster' ||
      location.pathname == '/home/:parent.processMaster' ||
      location.pathname == '/home/:iq3.processMaster' ||
      location.pathname == '/home/:iq5.processMaster'
    ) {
      processMasterRef.current.setSplitPaneSize(['45%']);
    } else if (
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      processPatternRef.current.setSplitPaneSize(['35%']);
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      materialClassNameRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq3.materialName') {
      materialNameRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      materialSurfaceRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq3.materialSize') {
      materialSizeRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq5.process') {
      processRef.current.setSplitPaneSize(['50%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialCalculate') {
      steelMaterialCalculateRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialClass') {
      steelMaterialClassRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      steelMaterialNameRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      steelMaterialRef.current.setSplitPaneSize(['60%']);
    }
  };

  const showGroupEditModal = () => {
    //　編集中の場合、破壊ポップアップを表示する。
    if (getCurrentEditMode()) {
      currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[2]);
      currentRef.current?.setEditModeCancelConfirm(true);
      return;
    }
    if (location.pathname == '/home/:common.customer') {
      customerRef.current.bulkEditProcess();
    } else if (location.pathname == '/home/:common.personInCharge') {
      personInChargeRef.current.bulkEditProcess();
    } else if (location.pathname == '/home/:common.purchase') {
      purchaseRef.current.bulkEditProcess();
    } else if (location.pathname == '/home/:common.coefficient') {
      coefficientRef.current.bulkEditProcess();
    } else if (location.pathname == '/home/:common.additional') {
      additionalItemsRef.current.bulkEditProcess();
    } else if (location.pathname == '/home/:common.project') {
      projectStatusRef.current.bulkEditProcess();
    } else if (location.pathname == '/home/:common.materialClass') {
      materialClassRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq3.material') {
      materialRef.current.updateMaterials();
    } else if (location.pathname == '/home/:iq3.material.process') {
      materialProcessRef.current.updateMaterialsProcess();
    } else if (location.pathname == '/home/:iq3.materialSize') {
      materialSizeRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq3.material.quality') {
      materialQualityRef.current.updateMaterialsQuality();
    } else if (location.pathname == '/home/:iq3.process') {
      processRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq3.referenceprice') {
      referencePriceRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      materialClassNameRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq3.materialName') {
      materialNameRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      materialSurfaceRef.current.showGroupEditModal();
    } else if (
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      processPatternRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq5.steelMaterialCalculate') {
      steelMaterialCalculateRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq5.steelMaterialClass') {
      steelMaterialClassRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      steelMaterialNameRef.current.showGroupEditModal();
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      steelMaterialRef.current.showGroupEditModal();
    }
  };

  const showDeleteModal = () => {
    if (location.pathname == '/home/:common.customer') {
      customerRef.current.deleteProcess();
    } else if (location.pathname == '/home/:common.personInCharge') {
      personInChargeRef.current.deleteProcess();
    } else if (location.pathname == '/home/:common.purchase') {
      purchaseRef.current.deleteProcess();
    } else if (location.pathname == '/home/:common.coefficient') {
      coefficientRef.current.deleteProcess();
    } else if (location.pathname == '/home/:common.additional') {
      additionalItemsRef.current.deleteProcess();
    } else if (location.pathname == '/home/:common.project') {
      projectStatusRef.current.deleteProcess();
    } else if (location.pathname == '/home/:common.autoReserve') {
      autoReserveRef.current.showDeleteModal();
    } else if (
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      processPatternRef.current.deletePatternInfo();
    } else if (
      location.pathname == '/home/:parent.device' ||
      location.pathname == '/home/:iq3.device' ||
      location.pathname == '/home/:iq5.device'
    ) {
      deviceRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq3.materialClass') {
      materialClassRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      materialClassNameRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq3.materialName') {
      materialNameRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      materialSurfaceRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq3.materialSize') {
      materialSizeRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq5.steelMaterialCalculate') {
      steelMaterialCalculateRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq5.steelMaterialClass') {
      steelMaterialClassRef.current.setSplitPaneSize(['60%']);
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      steelMaterialNameRef.current.showDeleteModal();
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      steelMaterialRef.current.showDeleteModal();
    }
  };

  const showCSVInputModal = () => {
    customerRef.current.showCSVInputModal();
  };

  const showAddNewModal = () => {
    //　編集中の場合、破壊ポップアップを表示する。
    if (getCurrentEditMode()) {
      currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[3]);
      currentRef.current?.setEditModeCancelConfirm(showConfirmMsgs?.moveInEdit);
      if (!showConfirmMsgs?.moveInEdit) {
        currentRef.current.discardConfirmOk();
        if (currentRef.current.createNewProcess != undefined) {
          currentRef.current?.createNewProcess();
        } else if (currentRef.current.showAddNewModal != undefined) {
          currentRef.current?.showAddNewModal();
        }
      }
      return;
    }
    if (location.pathname == '/home/:common.customer') {
      customerRef.current.createNewProcess();
    } else if (location.pathname == '/home/:common.personInCharge') {
      personInChargeRef.current.createNewProcess();
    } else if (location.pathname == '/home/:common.purchase') {
      purchaseRef.current.createNewProcess();
    } else if (location.pathname == '/home/:common.coefficient') {
      coefficientRef.current.createNewProcess();
    } else if (location.pathname == '/home/:common.additional') {
      additionalItemsRef.current.createNewProcess();
    } else if (location.pathname == '/home/:common.project') {
      projectStatusRef.current.createNewProcess();
    } else if (location.pathname == '/home/:common.autoReserve') {
      autoReserveRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.material') {
      materialRef.current.addNew();
    } else if (location.pathname == '/home/:iq3.material.process') {
      materialProcessRef.current.add();
    } else if (location.pathname == '/home/:iq3.material.size') {
      materialSizeRef.current.add();
    } else if (location.pathname == '/home/:iq3.material.quality') {
      materialQualityRef.current.add();
    } else if (location.pathname == '/home/:iq3.devicesetting') {
      deviceSettingRef.current.add();
    } else if (location.pathname == '/home/:iq3.timecharge') {
      timeChargeRef.current.add();
    } else if (location.pathname == '/home/:iq3.process') {
      processRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.referenceprice') {
      referencePriceRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.materialClass') {
      materialClassRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      materialClassNameRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.materialName') {
      materialNameRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.materialSize') {
      materialSizeRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      materialSurfaceRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:parent.processMaster') {
      processMasterRef.current.showAddNewModal();
    } else if (
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      processPatternRef.current.showAddNewModal();
    } else if (
      location.pathname == '/home/:parent.device' ||
      location.pathname == '/home/:iq3.device' ||
      location.pathname == '/home/:iq5.device'
    ) {
      deviceRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq5.material.calculate') {
      steelMaterialCalculateRef.current.add();
    } else if (location.pathname == '/home/:iq5.steelMaterialCalculate') {
      steelMaterialCalculateRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq5.material.name') {
      steelMaterialNameRef.current.add();
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      steelMaterialNameRef.current.showAddNewModal();
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      steelMaterialRef.current.showAddNewModal();
    }
  };

  const showCopyNewModal = () => {
    //　編集中の場合、破壊ポップアップを表示する。
    if (getCurrentEditMode()) {
      currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[3]);
      currentRef.current?.setEditModeCancelConfirm(showConfirmMsgs?.moveInEdit);
      if (!showConfirmMsgs?.moveInEdit) {
        currentRef.current.discardConfirmOk();
        if (currentRef.current.showCopyNewModal != undefined) {
          currentRef.current?.showCopyNewModal();
        }
      }
      return;
    }
    if (location.pathname == '/home/:iq5.steelMaterialName') {
      steelMaterialNameRef.current.showCopyNewModal();
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      steelMaterialRef.current.showCopyNewModal();
    }
  };

  const renderParameterComponent = () => {
    if (location.pathname == '/home/:common.customer') {
      return (
        <CustomerSupplierParamList
          ref={customerRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.personInCharge') {
      return (
        <PersonInChargeParamList
          ref={personInChargeRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.purchase') {
      return (
        <PurchaseParamList
          ref={purchaseRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.coefficient') {
      return (
        <CoefficientParamList
          ref={coefficientRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          rowSelectTbl={props?.rowSelectTbl}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.additional') {
      return (
        <AdditionalItemsParamList
          ref={additionalItemsRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectTbl={props?.rowSelectTbl}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.project') {
      return (
        <ProjectStatusParamList
          ref={projectStatusRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.materialClass') {
      return (
        <MaterialClassList
          ref={materialClassRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:common.autoReserve') {
      return (
        <AutoReserveList
          ref={autoReserveRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      return (
        <MaterialClassNameList
          ref={materialClassNameRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq3.materialName') {
      return (
        <MaterialNameList
          ref={materialNameRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      return (
        <MaterialSurfaceList
          ref={materialSurfaceRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq3.materialSize') {
      return (
        <MaterialSizeList
          ref={materialSizeRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (
      location.pathname == '/home/:iq3.processMaster' ||
      location.pathname == '/home/:parent.processMaster' ||
      location.pathname == '/home/:common.processMaster' ||
      location.pathname == '/home/:iq5.processMaster'
    ) {
      return (
        <ProcessMasterList
          ref={processMasterRef}
          setParam={setParamKouteiMaster}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
          updateCurrentParamSelected={props?.updateCurrentParamSelected}
        />
      );
    } else if (
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      return (
        <ProcessPatternParamList
          ref={processPatternRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (
      location.pathname == '/home/:parent.device' ||
      location.pathname == '/home/:iq3.device' ||
      location.pathname == '/home/:iq5.device'
    ) {
      return (
        <DevicesList
          ref={deviceRef}
          setParam={setParam}
          updateMainParameterEditMode={updateMainParameterEditMode}
          showConfirmMsgs={showConfirmMsgs}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq5.steelMaterialCalculate') {
      return (
        <SteelMaterialCalculateList
          ref={steelMaterialCalculateRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq5.steelMaterialClass') {
      return (
        <SteelMaterialClassList
          ref={steelMaterialClassRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      return (
        <SteelMaterialNameList
          ref={steelMaterialNameRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      return (
        <SteelMaterialList
          ref={steelMaterialRef}
          setParam={setParam}
          showConfirmMsgs={showConfirmMsgs}
          updateMainParameterEditMode={updateMainParameterEditMode}
          loading={props?.loading} // Waitingダイアログ
          onClickCSVImport={onClickCSVImport}
          onClickCSVExport={onClickCSVExport}
          updateSelectedRowId={updateSelectedRowId}
          selectedRowId={props?.selectedRowId}
          rowSelectPage={props?.rowSelectPage}
          initialParam={initialParam}
          resetInitialParam={resetInitialParam}
          ascSortFlg={props?.ascSortFlg}
          sortNm={props?.sortNm}
          filterDatas={props?.filterDatas}
        />
      );
    }
  };

  const setParam = (paramType, paramNm) => {
    setParamType(paramType);
    setParamSelected(paramNm);
  };

  const setParamKouteiMaster = (paramType) => {
    typesDropChange(paramType, true);
    if (paramType === 'common') {
      paramType = '共通';
    }
    setParamType(paramType);
    // props.updateParamSelected(paramType);
    setParamSelected('工程マスター');
  };
  // 開発中ポップアップを開く
  const openCommonTaiochuPopup = () => {
    setCommonTaiochuPopup(true);
  };

  // 開発中ポップアップを閉じる
  const closeCommonTaiochuPopup = () => {
    setCommonTaiochuPopup(false);
  };

  useImperativeHandle(ref, () => ({
    getCurrentEditMode: getCurrentEditMode,
  }));

  const getCurrentEditMode = () => {
    setEditMode(currentRef?.current?.getEditMode);
    return currentRef?.current?.getEditMode;
  };

  const updateMainParameterEditMode = (mode) => {
    setEditMode(mode);
  };

  const updateSelectedRowId = (selectedRowId, rowSelectTbl, rowSelectPage, ascSortFlg, sortNm, filterDatas) => {
    props?.updateSelectedRowId(selectedRowId, rowSelectTbl, rowSelectPage, ascSortFlg, sortNm, filterDatas);
  };

  const resetInitialParam = () => {
    setInitialParam('');
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    //　編集中の場合、破壊ポップアップを表示する。
    if (getCurrentEditMode()) {
      if (showConfirmMsgs?.moveInEdit) {
        if (
          location.pathname == '/home/:common.materialClass' ||
          location.pathname == '/home/:common.autoReserve' ||
          location.pathname == '/home/:iq3.materialClassName' ||
          location.pathname == '/home/:iq3.materialName' ||
          location.pathname == '/home/:iq3.materialSurface' ||
          location.pathname == '/home/:iq3.materialSize' ||
          location.pathname == '/home/:iq5.steelMaterialName' ||
          location.pathname == '/home/:iq5.steelMaterial'
        ) {
          currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[6]);
        } else {
          currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[4]);
        }
        currentRef.current?.setEditModeCancelConfirm(showConfirmMsgs?.moveInEdit);
        return;
      } else {
        currentRef.current.discardConfirmOk();
      }
    }
    csvImportRef.current.click();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = async () => {
    //　編集中の場合、破壊ポップアップを表示する。
    if (getCurrentEditMode()) {
      if (showConfirmMsgs?.moveInEdit) {
        if (
          location.pathname == '/home/:common.materialClass' ||
          location.pathname == '/home/:common.autoReserve' ||
          location.pathname == '/home/:iq3.materialClassName' ||
          location.pathname == '/home/:iq3.materialName' ||
          location.pathname == '/home/:iq3.materialSurface' ||
          location.pathname == '/home/:iq3.materialSize' ||
          location.pathname == '/home/:iq5.steelMaterialName' ||
          location.pathname == '/home/:iq5.steelMaterial'
        ) {
          currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[7]);
        } else {
          currentRef.current?.setDiscardChangeMode(currentRef.current?.discardChange[5]);
        }
        currentRef.current?.setEditModeCancelConfirm(showConfirmMsgs?.moveInEdit);
        return;
      } else {
        currentRef.current.discardConfirmOk();
      }
    }

    let name = '';
    let data = '';
    let link = csvExportRef.current.link;
    if (location.pathname == '/home/:common.customer') {
      name = customerRef.current.getCsvExportFileName;
      data = await customerRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.personInCharge') {
      name = personInChargeRef.current.getCsvExportFileName;
      data = await personInChargeRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.purchase') {
      name = purchaseRef.current.getCsvExportFileName;
      data = purchaseRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.coefficient') {
      name = coefficientRef.current.getCsvExportFileName;
      data = coefficientRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.additional') {
      name = additionalItemsRef.current.getCsvExportFileName;
      data = additionalItemsRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.project') {
      name = projectStatusRef.current.getCsvExportFileName;
      data = await projectStatusRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.materialClass') {
      name = materialClassRef.current.getCsvExportFileName;
      data = await materialClassRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:common.autoReserve') {
      name = autoReserveRef.current.getCsvExportFileName;
      data = autoReserveRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:iq3.materialClassName') {
      name = materialClassNameRef.current.getCsvExportFileName;
      data = await materialClassNameRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:iq3.materialName') {
      name = materialNameRef.current.getCsvExportFileName;
      data = await materialNameRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:iq3.materialSurface') {
      name = materialSurfaceRef.current.getCsvExportFileName;
      data = await materialSurfaceRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:iq3.materialSize') {
      name = materialSizeRef.current.getCsvExportFileName;
      data = await materialSizeRef.current.getCsvExportData();
    } else if (
      location.pathname == '/home/:iq3.processMaster' ||
      location.pathname == '/home/:parent.processMaster' ||
      location.pathname == '/home/:common.processMaster'
    ) {
      name = processMasterRef.current.getCsvExportFileName;
      data = processMasterRef.current.getCsvExportData();
    } else if (
      location.pathname == '/home/:iq3.patternProcess' ||
      location.pathname == '/home/:parent.patternProcess' ||
      location.pathname == '/home/:iq5.patternProcess'
    ) {
      name = processPatternRef.current.getCsvExportFileName;
      data = processPatternRef.current.getCsvExportData();
    } else if (
      location.pathname == '/home/:parent.device' ||
      location.pathname == '/home/:iq3.device' ||
      location.pathname == '/home/:iq5.device'
    ) {
      name = deviceRef.current.getCsvExportFileName;
      data = deviceRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:iq5.steelMaterialName') {
      name = steelMaterialNameRef.current.getCsvExportFileName;
      data = await steelMaterialNameRef.current.getCsvExportData();
    } else if (location.pathname == '/home/:iq5.steelMaterial') {
      name = steelMaterialRef.current.getCsvExportFileName;
      data = await steelMaterialRef.current.getCsvExportData();
    }
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([BOM, data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.click();
  };

  // CSV入力ファイルを選択した場合、
  const handleCSVFileChange = (event) => {
    //FileReaderの定義
    const reader = new FileReader();
    let file = event.target.files[0];
    reader.onload = async () => {
      let result = await reader?.result?.split('\n');
      // IQX_WEBEST-301 パラメータ入出力機能において、エクセルで保存したCSVファイルも入力ファイルとして使用できるようにして欲しい。
      if (reader?.result?.includes('\r\n')) {
        // エクセル入力の場合
        result = await reader?.result?.split('\r\n');
      }
      if (location.pathname == '/home/:common.customer') {
        customerRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.personInCharge') {
        personInChargeRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.purchase') {
        purchaseRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.coefficient') {
        coefficientRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.additional') {
        additionalItemsRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.project') {
        projectStatusRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.materialClass') {
        materialClassRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:common.autoReserve') {
        autoReserveRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:iq3.materialClassName') {
        materialClassNameRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:iq3.materialName') {
        materialNameRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:iq3.materialSurface') {
        materialSurfaceRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:iq3.materialSize') {
        materialSizeRef.current.submitCsvImportData(result);
      } else if (
        location.pathname == '/home/:iq3.processMaster' ||
        location.pathname == '/home/:parent.processMaster' ||
        location.pathname == '/home/:common.processMaster'
      ) {
        processMasterRef.current.submitCsvImportData(result);
      } else if (
        location.pathname == '/home/:iq3.patternProcess' ||
        location.pathname == '/home/:parent.patternProcess' ||
        location.pathname == '/home/:iq5.patternProcess'
      ) {
        processPatternRef.current.submitCsvImportData(result);
      } else if (
        location.pathname == '/home/:parent.device' ||
        location.pathname == '/home/:iq3.device' ||
        location.pathname == '/home/:iq5.device'
      ) {
        deviceRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:iq5.steelMaterialName') {
        steelMaterialNameRef.current.submitCsvImportData(result);
      } else if (location.pathname == '/home/:iq5.steelMaterial') {
        steelMaterialRef.current.submitCsvImportData(result);
      }
    };
    reader.readAsText(file);
  };

  return (
    <>
      {location.pathname.indexOf('/home/:common.') > -1 ||
      location.pathname.indexOf('/home/:parent.') > -1 ||
      location.pathname.indexOf('/home/:iq3.') > -1 ||
      location.pathname.indexOf('/home/:iq5.') > -1 ? (
        <div style={{ overflow: 'hidden' }}>
          <Form className="components-table-demo-control-bar" form={form} onFinish={onFinish}>
            <div
              style={{
                borderBottom: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#212529',
                backgroundColor: '#fafafc',
              }}
            >
              <Row style={{ marginLeft: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '69%' }}>
                  <Row style={{ marginTop: '2px', marginLeft: '0', marginBottom: '2px' }}>
                    <Col span={9}>
                      <Space size="middle">
                        <Select
                          style={{ width: '90px', height: '30px', fontSize: '10px' }}
                          onChange={(e) => {
                            setParamType1(e);
                            typesDropChange1(e, false);
                            setInitialParam('');
                          }}
                          name="paramType"
                          value={paramType1}
                        >
                          {dropTypes1?.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                        <Select
                          style={{ width: '90px', height: '30px', fontSize: '10px' }}
                          onChange={(e) => {
                            setParamType2(e);
                            typesDropChange2(e, false);
                            setInitialParam('');
                          }}
                          name="paramType"
                          value={paramType2}
                        >
                          {dropTypes2Temp?.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>

                        <Select
                          className="search-select-input"
                          onChange={(e) => {
                            paramDropChange(false, e);
                            setInitialParam('');
                          }}
                          name="paramDrop"
                          value={paramSelected}
                          options={
                            paramType2 === 'ALL'
                              ? dropParamsTemp?.filter((option) => option.keyword2?.includes('iq3'))?.length <= 0 ||
                                dropParamsTemp?.filter((option) => option.keyword2?.includes('iq5'))?.length <= 0
                                ? [
                                    {
                                      label: <span className="custom-label">---共通---</span>,
                                      options: dropParamsTemp?.filter((option) => option.keyword2?.includes('common')),
                                    },
                                  ]
                                : [
                                    {
                                      label: <span className="custom-label">---共通---</span>,
                                      options: dropParamsTemp?.filter((option) => option.keyword2?.includes('common')),
                                    },
                                    {
                                      label: <span className="custom-label">---板金---</span>,
                                      options: dropParamsTemp?.filter((option) => option.keyword2?.includes('iq3')),
                                    },
                                    {
                                      label: <span className="custom-label">---形鋼---</span>,
                                      options: dropParamsTemp?.filter((option) => option.keyword2?.includes('iq5')),
                                    },
                                  ]
                              : dropParamsTemp
                          }
                        ></Select>

                        {!noAddDeletEditBtn?.includes(location.pathname) && (
                          <Tooltip title="新規作成" placement="bottom" overlayClassName="tooltip-text">
                            <a onClick={showAddNewModal}>
                              <Image
                                preview={false}
                                width={iconWidth}
                                src={new_icon}
                                style={{ marginLeft: '0px' }}
                              ></Image>
                            </a>
                          </Tooltip>
                        )}
                        {noCopyBtn?.includes(location.pathname) && (
                          <Tooltip title="複写" placement="bottom" overlayClassName="tooltip-text">
                            <a onClick={showCopyNewModal}>
                              <Image preview={false} width={iconWidth} src={copy} style={{ marginLeft: '0px' }}></Image>
                            </a>
                          </Tooltip>
                        )}
                        {/* ファーストリリースでは不要 */}
                        {/* <Tooltip title="CSV取込" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={openCommonTaiochuPopup}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={import_csv_icon}
                          style={{ marginLeft: '0px' }}
                        ></Image>
                      </a>
                    </Tooltip>

                    <Tooltip title="CSV出力" placement="bottom" overlayClassName="tooltip-text">
                     // <CSVLink filename={'Parameter_取引先.csv'} preview={true} data={customersData}>
                      <a onClick={openCommonTaiochuPopup}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={export_csv_icon}
                          style={{ marginLeft: '0px' }}
                        ></Image>
                        //</CSVLink>
                      </a>
                    </Tooltip> */}
                        {!noAddDeletEditBtn?.includes(location.pathname) && (
                          <Tooltip title="削除" placement="bottom" overlayClassName="tooltip-text">
                            <a onClick={showDeleteModal}>
                              <Image
                                preview={false}
                                width={iconWidth}
                                src={child_delete_icon}
                                style={{ marginLeft: '0px' }}
                              ></Image>
                            </a>
                          </Tooltip>
                        )}

                        {!noAddCSVImportBtn?.includes(location.pathname) && (
                          <Tooltip title="CSV入力" placement="bottom" overlayClassName="tooltip-text">
                            <input
                              type="file"
                              accept=".csv"
                              ref={csvImportRef}
                              style={{ display: 'none' }}
                              onChange={handleCSVFileChange}
                              onClick={(e) => (e.target.value = null)}
                            />
                            <Image
                              preview={false}
                              width={iconWidth}
                              src={import_csv_icon}
                              style={{ marginLeft: '0px' }}
                              onClick={onClickCSVImport}
                            />
                          </Tooltip>
                        )}

                        {!noAddCSVExportBtn?.includes(location.pathname) && (
                          <Tooltip title="CSV出力" placement="bottom" overlayClassName="tooltip-text">
                            <CSVLink ref={csvExportRef} data={''} preview={true} style={{ display: 'none' }}></CSVLink>
                            <Image
                              preview={false}
                              width={iconWidth}
                              src={export_csv_icon}
                              style={{ marginLeft: '0px' }}
                              onClick={onClickCSVExport}
                            />
                          </Tooltip>
                        )}

                        {/* IQX_WEBEST-225 パラメータの一括編集コマンドがほしい */}
                        {!(
                          noAddDeletEditBtn?.includes(location.pathname) || noGpEditBtn?.includes(location.pathname)
                        ) && (
                          <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
                            <a onClick={showGroupEditModal}>
                              <Image
                                preview={false}
                                width={27}
                                src={edit_list}
                                style={{ marginLeft: '0px', paddingTop: '5px' }}
                              ></Image>
                            </a>
                          </Tooltip>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '29%' }}>
                  <Row style={{ marginTop: '2px', marginLeft: '10px' }}>
                    {editMode ? (
                      <Col span={editMode ? 20 : 0}>
                        <Input
                          className="editInfoTle"
                          value={'編集中'}
                          type="text"
                          prefix={
                            <Image
                              preview={false}
                              width={13}
                              src={edit_info}
                              style={{
                                transform: 'rotate(270deg)',
                                marginRight: '5px',
                              }}
                            />
                          }
                        />
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col span={editMode ? 4 : 5} offset={editMode ? 0 : 10}>
                      <Space size="middle">
                        {btnShowHide ? (
                          <RightCircleFilled onClick={contentResizeBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                        ) : (
                          <LeftCircleFilled
                            onClick={contentResizeBackwardBtn}
                            style={{ color: '#1063aa', fontSize: 28 }}
                          />
                        )}
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
            {renderParameterComponent()}
          </Form>

          {/** 開発中ポップアップ */}
          {commonTaiochuPopup ? commonTaiochuModal(commonTaiochuPopup, closeCommonTaiochuPopup) : ''}

          {/** 画面切替警告メッセージポップアップ */}
          {editModeCancelConfirm
            ? commonModal(
                editModeCancelConfirm,
                confirmScreenChangeTitle,
                commonFooter(discardConfirmOk, discardConfirmCancel),
                null,
                400,
                discardConfirmOk,
                discardConfirmCancel,
                confirmScreenChangeContent,
                null,
                null,
                false
              )
            : ''}
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default MainParameter;
