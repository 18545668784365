/**
 * クラス名：工程パターン情報一覧画面
 * 説明：親部品・板金の共通使う工程パターン情報一覧ファイルである。
 * 作成者：ナンス
 * 作成日：2023/04/01
 * バージョン：1.0
 */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table, Button, Row, Input, Col, Modal, Select, Image, Checkbox } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
// Content resize
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table as RTable } from 'react-bootstrap';
import { StatusCodes } from 'http-status-codes';

import '../../../../assets/styles/common.css';
import ProcessPatternDetails from './ProcessPatternDetails';
import {
  getInitDataForProcess,
  today,
  discardChange,
  updateAccessToken,
  getAccessToken,
  formatDate,
  isContractor,
  formValidatorMode,
  patternPage,
} from '../../../common/Common';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  commonActionFooter,
  authorizeError,
  error,
  commonDeleteFooter,
  confirmScreenChangeTitle,
} from '../../../common/CommonModal';
import {
  bulkEditProcessPatternData,
  createProcessPatternData,
  getClientInfo,
  getDeviceInfo,
  getProcessInfo,
  getProcessPatternInfo,
  getProcessPatternInfoById,
  updateProcessPatternData,
} from '../../../common/CommonAPI';
import { ProcessDetailGroup, ServiceClass, WorkType } from '../../../common/enums';
import { ErrorMessage } from '../../../common/Message';
import { pageSizes } from '../../../common/Constant';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import ProcessPatternForm from './ProcessPatternForm';

dayjs.extend(customParseFormat);

const ProcessPatternParamList = forwardRef((props, ref) => {
  const detailRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  // Content Resize
  const [sizes, setSizes] = useState(['35%']);
  // add new
  const [addNewModal, setAddNewModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deletePatternInfoModal, setDeletePatternInfoModal] = useState(false);
  const [surfaceSelectedRowKeys, setSurfaceSelectedRowKeys] = useState([]);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataAdd, setSelectedDataAdd] = useState([]);
  const [selectedPatternNo, setSelectedPatternNo] = useState(0);
  const [newPatternNm, setNewPatternNm] = useState('');

  // 編集モード
  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [changeRecordKey, setChangeRecordKey] = useState(0);
  const [copyPatternInfo, setCopyPatternInfo] = useState([]);
  const [defaultProcessPattern, setDefaultProcessPattern] = useState([]);
  const [createConfirm, setCreateConfirm] = useState(false);

  // マスタ情報
  const [processMasterData, setProcessMasterData] = useState([]);
  const [deviceMasterData, setDeviceMasterData] = useState([]);
  const [clientMasterData, setClientMasterData] = useState([]);

  const [discardChangeMode, setDiscardChangeMode] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  // pagination
  const [pageSize, setPageSize] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [tblLoading, setTblLoading] = useState(false);

  const [copyCheck, setCopyCheck] = useState(false);
  const [copyCheckMsg, setCopyCheckMsg] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);
  const [childTitle, setChildTitle] = useState('');
  const [editPageSize, setEditPageSize] = useState(100);
  const [editPage, setEditPage] = useState(1);
  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [bulkEditInfo, setBulkEditInfo] = useState({});
  const [isAllEdit, setIsAllEdit] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];

  // デフォルトページの表示件数
  const defaultPageSize = 25;

  const inputItemRef = {};

  // useEffect(() => {
  //   settingDefaultProcessPattern();
  // }, []);

  useEffect(() => {
    getProcessPattern();
  }, [currentRoute]);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 工程パターンマスタの情報を取得する
  const getProcessPattern = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let navigatePage = ':parent.patternProcess';
    let paramType = 'parent';
    let paramSelected = 'oya_patternProcess';
    let patternLst = [];
    let pageBefore =
      (props.initialParam === 'oya_patternProcess' ||
        props.initialParam === 'iq3_patternProcess' ||
        props.initialParam === 'iq5_patternProcess') &&
      props?.rowSelectPage !== undefined &&
      props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let patternInfos = await getProcessPatternInfo(pageSize, pageBefore, getCurrentServiceClass(), ascSortFlg, sortNm);
    if (patternInfos) {
      patternLst = patternInfos ? patternInfos?.data : [];
      setTotalCount(patternInfos?.totalCount ? patternInfos?.totalCount : 0);
    }
    let currentServiceClass = getCurrentServiceClass();
    await settingDefaultProcessPattern(currentServiceClass);
    await getClientDataInfo();
    if (currentServiceClass === ServiceClass.Parent) {
      // 親部品の場合、
    } else if (currentServiceClass === ServiceClass.SheetMetal) {
      // 板金の場合、
      navigatePage = ':iq3.patternProcess';
      paramType = 'iq3';
      paramSelected = 'iq3_patternProcess';
    } else if (currentServiceClass === ServiceClass.Beams) {
      // 形鋼の場合、
      navigatePage = ':iq5.patternProcess';
      paramType = 'iq5';
      paramSelected = 'iq5_patternProcess';
    }
    // DBからデータ取得
    setInitialTableItems(patternLst);
    let initialInfo = patternLst?.length > 0 ? patternLst?.[0] : [];
    // 前回選択された行がある場合
    if (patternLst?.length > 0) {
      if (
        (props.initialParam === 'oya_patternProcess' ||
          props.initialParam === 'iq3_patternProcess' ||
          props.initialParam === 'iq5_patternProcess') &&
        props.selectedRowId !== undefined &&
        props.selectedRowId !== 0
      ) {
        let selectedRowData = patternLst?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          initialInfo = await getProcessPatternInfoById(selectedRowData?.[0]?.id);
        }
      } else {
        initialInfo = await getProcessPatternInfoById(patternLst[0]?.id);
      }
    }
    if (initialInfo?.length > 0) {
      setSelectedRowKeys(initialInfo?.length > 0 ? initialInfo[0].id : 0);
      setSelectedData(initialInfo?.length > 0 ? initialInfo[0] : []);
    }
    props?.setParam(paramType, paramSelected);
    navigate(navigatePage);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 委託先情報を取得する
  const getClientDataInfo = async () => {
    let result = await getClientInfo(0, 0, 'ASC', '', {});
    if (result) {
      result = result?.data;
      result = result?.filter((record) => isContractor(record.clientType) && record.info.isUsed);
      setClientMasterData(result);
    }
  };

  // 工程パターンのルートパスで「親部品／板金」の判断
  const getCurrentServiceClass = () => {
    let route = ServiceClass.Parent;
    if (currentRoute?.includes('parent')) {
      route = ServiceClass.Parent;
    } else if (currentRoute?.includes('iq3')) {
      route = ServiceClass.SheetMetal;
    } else if (currentRoute?.includes('iq5')) {
      route = ServiceClass.Beams;
    }
    return route;
  };

  const onSelectChange = (checked, record, index, originNode) => {
    setSurfaceSelectedRowKeys(checked);
  };

  const rowSelection = {
    surfaceSelectedRowKeys,
    onChange: onSelectChange,
  };

  const updateSurface = () => {
    setUpdateItem(data);
    setIsUpdate(true);
  };

  const updateOK = () => {
    setUpdateConfirm(true);
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setIsUpdate(false);
    setCancelConfirm(false);
    setSelectedRowKeys(changeRecordKey);
    setSelectedData(initialTblItems?.find((item) => item.id === changeRecordKey));
    detailRef?.current?.discardConfirmOk();
    if (discardChangeMode.state === discardChange[3].state) {
      setAddNewModal(true);
      setNewPatternNm(selectedData?.name);
      setSelectedPatternNo(selectedData?.id);
      setCopyPatternInfo(selectedData);
      setSelectedDataAdd(selectedData);
    }
  };

  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    setCancelConfirm(false);
    detailRef?.current?.discardConfirmCancel();
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode) => {
    let ret = true;
    let message = [];
    // 工程パターン名
    let input =
      procMode === formValidatorMode.Create ? inputItems['newPatternNm']?.input : inputItems['patternName']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('工程パターン名').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 確認ポップアップのOKボタン押下
  const confirmOk = (e) => {
    // 画面の入力チェック
    if (!formValidation(inputItemRef, formValidatorMode.Create)) {
      return;
    }
    setCreateConfirm(true);
    // IQX_WEBEST-259
    if (copyCheck) {
      setCopyCheckMsg(true);
    }
  };

  const confirmCancel = (e) => {
    //setCancelConfirm(true);
    setCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      cancelConfirmOK();
    }
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    setCancelConfirm(false);
    setAddNewModal(false);
    setIsBulkEdit(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const updateCancel = () => {
    setCancelConfirm(true);
  };

  const addNew = () => {
    setAddNewModal(true);
  };

  const updateClose = () => {
    setIsUpdate(false);
  };

  const onRowSelect = async (record) => {
    if (record) {
      let patternInfo = await getProcessPatternInfoById(record?.id);
      if (patternInfo?.length > 0) {
        record = patternInfo[0];
      }
    }
    setChangeRecordKey(record.id);
    if (editMode) {
      // setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
      setSelectedRowKeys(record.id);
      setSelectedData(record);
    } else {
      setSelectedRowKeys(record.id);
      setSelectedData(record);
      if (getCurrentServiceClass() === ServiceClass.Parent) {
        navigate(':parent.patternProcess');
      } else {
        navigate(':iq3.patternProcess');
      }
    }
    props?.updateSelectedRowId(record?.id, 0, currentPage);
    props?.resetInitialParam();
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let ascSortFlg = 'ASC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    let currentClass = currentRoute?.includes('parent')
      ? ServiceClass.Parent
      : currentRoute?.includes('iq3')
      ? ServiceClass.SheetMetal
      : ServiceClass.Beams;
    let patternInfo = await getProcessPatternInfo(pageSize, pageBefore, currentClass, ascSortFlg, sorter?.columnKey);
    if (patternInfo) {
      const patternInfos = patternInfo ? patternInfo?.data : [];
      setInitialTableItems(patternInfos);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  const settingDiscardChangeMode = (mode) => {
    setDiscardChangeMode(mode);
  };

  const settingEditModeCancelConfirm = (mode) => {
    setEditModeCancelConfirm(mode);
  };

  const showAddNewModal = () => {
    if (editMode) {
      //setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        discardConfirmOk();
      }
    } else {
      setAddNewModal(true);
      setNewPatternNm(selectedData?.name);
      setSelectedPatternNo(selectedData?.id);
      setCopyPatternInfo(selectedData);
      setSelectedDataAdd(selectedData);
    }
  };

  const deletePatternInfo = () => {
    if (editMode) {
      /*  setEditModeCancelConfirm(true);
      setDiscardChangeMode(''); */
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        discardConfirmOk();
      }
      if (!moveInEdit) {
        setIsUpdate(false);
        setCancelConfirm(false);
        setSelectedRowKeys(changeRecordKey);
        setSelectedData(initialTblItems?.find((item) => item.id === changeRecordKey));
        detailRef?.current?.discardConfirmOk();
        setDeletePatternInfoModal(true);
      } else setDiscardChangeMode('');
    } else {
      setDeletePatternInfoModal(true);
    }
  };

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    addNew: addNew,
    updateSurface: updateSurface,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    showAddNewModal: showAddNewModal,
    deletePatternInfo: deletePatternInfo,
    setDiscardChangeMode: settingDiscardChangeMode,
    setEditModeCancelConfirm: settingEditModeCancelConfirm,
    discardChange: discardChange,
    discardConfirmOk: discardConfirmOk,
    showGroupEditModal: showGroupEditModal,
  }));

  // デフォルトパターンの情報を取得する
  const settingDefaultProcessPattern = async (currentServiceClass) => {
    let processMasterData = [];
    let processList = [];
    let deviceList = [];
    let multiProcessList = [];
    const processMaster = await getProcessInfo(); // 工程マスタデータ
    const deviceMasterInfo = await getDeviceInfo(0, 0, getCurrentServiceClass(), 'ASC', 'id', {}); // 装置マスタデータ
    let deviceMaster = [];
    if (deviceMasterInfo) {
      deviceMaster = deviceMasterInfo?.data;
    }
    setDeviceMasterData(deviceMaster);

    //工程マスターデータにより種類選択ボックスデータ準備
    if (currentServiceClass === ServiceClass.Parent) {
      // 親部品の場合、
      processMasterData = processMaster?.filter((item) => item.class === ServiceClass.Parent);
    } else if (currentServiceClass === ServiceClass.SheetMetal) {
      // 板金の場合、
      processMasterData = processMaster?.filter((item) => item.class === ServiceClass.SheetMetal);
    } else if (currentServiceClass === ServiceClass.Beams) {
      // 形鋼の場合、
      processMasterData = processMaster?.filter((item) => item.class === ServiceClass.Beams);
    }
    deviceList = deviceMaster?.filter((item) => processMasterData?.filter((process) => process?.id == item?.processId));
    if (deviceList !== null && processMasterData !== null) {
      setProcessMasterData(processMasterData);
      processMasterData?.forEach((master) => {
        if (master.isAdditional) {
          // 追加タイプの場合、
          let processDetailTypes = master.details?.processDetailTypes;
          for (let j = 0; j < processDetailTypes?.length; j++) {
            let device = deviceList
              ?.filter((item) => item.processId === master.id)
              ?.filter((device) => device?.details?.addProcessTypeId === processDetailTypes[j]?.id);
            // processList.push({
            //   key: key,
            //   processType: master.processType,
            //   processName: master.processName,
            //   checked: 0,
            //   deviceCodeKey: customTargetSochi[0] ? customTargetSochi[0].key : '',
            //   deviceName:
            //     customTargetSochi[0] && customTargetSochi[0].deviceName ? customTargetSochi[0].deviceName : '',
            //   numberOfProcess: master.numberOfProcess,
            //   processDetailLst: [],
            //   companyCode: customTargetSochi[0] ? customTargetSochi[0].customerCode : '0',
            //   itakuFlag: customTargetSochi[0] ? customTargetSochi[0].itakuFlag : false,
            //   addProType: processDetailValue[j]?.detailType,
            // });
            // key++;
            multiProcessList = [];
            multiProcessList.push({
              processNo: 1,
              processDetailGroup: ProcessDetailGroup.None,
              processDetailTypeId: processDetailTypes[j]?.id,
            });
            processList.push({
              processId: master?.id,
              deviceId: device?.length > 0 ? device?.[0].id : 0,
              isUsedForCalc: false,
              multiprocesses: multiProcessList,
            });
          }
        } else {
          let device = deviceList?.filter((item) => item.processId == master.id)?.[0];
          multiProcessList = [];
          for (let i = 1; i <= master?.details?.processQty; i++) {
            multiProcessList.push({
              processNo: i,
              processDetailGroup: ProcessDetailGroup.None,
              processDetailTypeId: 0,
            });
          }
          processList.push({
            processId: master?.id,
            deviceId: device?.id,
            isUsedForCalc: false,
            multiprocesses: multiProcessList,
          });
        }
      });
      setDefaultProcessPattern(processList);
    }
  };

  // 登録確認メッセージのOKボタン押下
  const addOK = async () => {
    setAddNewModal(false);
    setCreateConfirm(false);
    let newPatternInfo = [];
    let patternProcess = [];
    // IQX_WEBEST-259
    if (copyCheck) {
      if (copyPatternInfo.details !== undefined) {
        patternProcess = copyPatternInfo.details;
      } else {
        patternProcess = defaultProcessPattern;
      }
    } else {
      patternProcess = defaultProcessPattern;
    }
    var lastRecord = initialTblItems?.length > 0 ? initialTblItems[initialTblItems?.length - 1] : [];
    newPatternInfo.push({
      name: newPatternNm,
      class: getCurrentServiceClass(),
      details: patternProcess,
    });
    const data = [...initialTblItems];
    let createdData = {};
    props?.loading(true); // Waitingダイアログを表示にする
    createdData = await createProcessPattern(newPatternInfo[0]);

    data.push(createdData);
    setInitialTableItems(data);
    setSelectedRowKeys(createdData?.id);
    setSelectedData(data?.length > 0 ? data?.find((item) => item.id === createdData?.id) : []);
    setCurrentPage(1);
    props?.updateSelectedRowId(createdData?.id, 0, 1);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // Idで降順する
  // const descOrderById = (data) => {
  //   let sortedList = data.sort((a, b) => b.id - a.id);
  //   return sortedList;
  // };

  // 工程パターンマスタ情報をDBに新規登録する
  async function createProcessPattern(createData) {
    let createdData = await createProcessPatternData(createData);
    return createdData;
  }

  const addCancel = () => {
    setCreateConfirm(false);
  };

  const deleteOK = async () => {
    setDeletePatternInfoModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteProcessPatternData(selectedData.id);
    // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
    if (deletedData !== undefined && deletedData?.id > 0) {
      let data = [...initialTblItems];
      data = data?.filter((item) => item.id != deletedData?.id);
      setInitialTableItems(data);
      setSelectedRowKeys(data?.length > 0 ? data[0].id : 0);
      setSelectedData(data?.length > 0 ? data[0] : []);
      props?.updateSelectedRowId(data?.length > 0 ? data[0].id : 0, 0, currentPage);
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 工程パターンマスタ情報をDBに削除する
  async function deleteProcessPatternData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setDeletePatternInfoModal(false);
  };

  const getInitData = (id) => {
    var data = getInitDataForProcess();
    var ret = data?.find((i) => i.id === id);
    return ret;
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const onChangeCopyPatternInfo = async (id) => {
    let patternInfo = initialTblItems?.filter((item) => item.id == id);
    if (patternInfo) {
      let patternInfoDetail = await getProcessPatternInfoById(patternInfo[0]?.id);
      if (patternInfoDetail?.length > 0) {
        patternInfo = patternInfoDetail;
      }
    }
    setCopyPatternInfo(patternInfo[0]);
    setSelectedPatternNo(id);
    setSelectedDataAdd(patternInfo[0]);
  };

  const updateProcessPatternList = async (updateData) => {
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    if (updateData != null) {
      updatedInfo = await updateProcessPattern(updateData);
      const data = [...initialTblItems];
      const index = data?.findIndex((item) => item?.id === updatedInfo?.id);
      const updatedItems = [...data];
      updatedItems[index] = updatedInfo;
      setInitialTableItems(updatedItems);
      setSelectedData(updatedInfo);
      setSelectedRowKeys(updatedInfo?.id);
      props?.updateSelectedRowId(updatedInfo?.id, 0, currentPage);
    }
    props?.loading(false); // Waitingダイアログを非表示にする
    setEditMode(false);
  };

  // 工程パターンマスタ情報をDBに更新する
  async function updateProcessPattern(updateData) {
    let ret = await updateProcessPatternData(updateData);
    return ret;
  }

  const updateSelectedData = (selectedProcessPattern) => {
    setSelectedData(selectedProcessPattern);
    setSelectedRowKeys(selectedProcessPattern.id);
  };

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>選択したデータをまとめて編集</p>
    </div>
  );

  // 新規追加Modalの入力項目
  const addNewContent = (
    <>
      <Row style={{ marginTop: 0 }}>
        <RTable className="propertiesTbl">
          <thead>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">No</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Input
                  type="text"
                  value={initialTblItems?.length + 1}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-non-editable'}
                ></Input>
              </td>
            </tr>
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">工程パターン名</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Input
                  type="text"
                  value={newPatternNm}
                  onChange={(e) => setNewPatternNm(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-editable'}
                  ref={(ref) => {
                    inputItemRef['newPatternNm'] = ref;
                  }}
                  maxLength={2048}
                ></Input>
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row style={{ marginLeft: 150 }}>
        <Col span={5} style={{ fontSize: 12, textAlign: 'right', paddingTop: 5 }}>
          <Checkbox
            style={{ marginRight: 10 }}
            type="checkbox"
            checked={copyCheck}
            onChange={(e) => {
              setCopyCheck(e.target.checked);
            }}
          >
            コピーして作成する
          </Checkbox>
        </Col>
        <Col span={10}>
          <Select
            disabled={!copyCheck}
            placeholder="選択"
            value={selectedPatternNo}
            style={{ fontSize: 11, width: '100%' }}
            onChange={onChangeCopyPatternInfo}
          >
            {initialTblItems?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: -9 }}>
        <Col span={24} style={{ fontSize: 12 }}>
          <div
            className={copyCheck ? 'overflow-auto' : 'process-pattern-details-disabled'}
            style={{ marginTop: copyCheck ? 3 : 10, height: copyCheck ? 690 : 560 }}
          >
            <ProcessPatternDetails
              ref={detailRef}
              editMode={editMode}
              selectedData={selectedDataAdd}
              listData={initialTblItems}
              getInitData={getInitData}
              processMasterData={processMasterData}
              deviceMasterData={deviceMasterData}
              clientMasterData={clientMasterData}
              updateEditMode={updateEditMode}
              updateProcessPatternList={updateProcessPatternList}
              isAdd={true}
              showEdtConfirmOK={showEdtConfirmOK}
              showEdtConfirmCancle={showEdtConfirmCancle}
              formValidation={formValidation}
            />
          </div>
        </Col>
      </Row>
    </>
  );

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'key',
      width: 55,
      className: 'cm-a-right',
      render: (id, record, index) => {
        let pageBefore = currentPage - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        return startPage + (index + 1); // IQX_WEBEST-240
      },
    },
    {
      id: '2',
      title: '工程パターン名',
      dataIndex: 'name',
      width: 150,
      sorter: (a, b) => setSortNm('name'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'name',
    },
    {
      id: '3',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
      sorter: (a, b) => setSortNm('created'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'created',
    },
    {
      id: '4',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
      sorter: (a, b) => setSortNm('modified'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modified',
    },
    {
      id: '5',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
      sorter: (a, b) => setSortNm('modifier'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modifier',
    },
  ];

  const initialUpdateValues = [1, 2, 3, 4, 5, 6, 7, 8];
  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const updateColumns = columns?.filter((item) => {
    return (
      item.id ==
      initialUpdateValues?.filter((i) => {
        if (i == item.id) {
          return i;
        }
      })
    );
  });

  // pagination
  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // IQX_WEBEST-259
  const copyCheckMsgContent = (
    <p style={{ fontSize: '13.5px' }}>
      コピー元で使用されている下記の装置は選択できない為、
      <br />
      別の装置に変更されます。
      <br />
      <br />
      装置名：{newPatternNm}
    </p>
  );

  const copyConfirmOk = () => {
    setCopyCheckMsg(false);
  };

  const copyConfirmCancel = () => {
    setCopyCheckMsg(false);
  };

  const resetInputInfo = () => {};

  const showGroupEditModal = () => {
    resetInputInfo();
    setBulkEditSelectedRow([]);
    setBulkEditSelectedRowId([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setBulkEditInfo({});
    setChildTitle('工程パターン（選択したデータをまとめて編集）');
    setIsAllEdit(true);
  };

  const onRowCheckboxSelect = async (checked, record, index) => {
    let checkedRow = [...checked];
    if (record?.length > 0) {
      let patternInfo = await getProcessPatternInfoById(record[0]?.id);
      if (patternInfo?.length > 0) {
        record[0] = patternInfo[0];
      }
    }
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow?.length);
    if (getCurrentServiceClass() === ServiceClass.Parent) {
      navigate(':parent.patternProcess');
    } else {
      navigate(':iq3.patternProcess');
    }
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const handlePageSizeChangeEdit = (current, pagesize) => {
    setEditPageSize(pagesize);
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const updateKouteiSentakuInfo = (selectedProcessPattern) => {
    setBulkEditInfo(selectedProcessPattern);
  };

  const updConfirmOk = async () => {
    setIsUpdate(false);
    setUpdateConfirm(false);
    setIsBulkEdit(false);
    if (bulkEditSelectedRow?.length > 0) {
      let editDatas = [];
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let bulkData = bulkEditSelectedRow[i];
        let bulkEditDataInfo = bulkEditInfo; // IQX_WEBEST-312 工程パターン（共通と板金、両方）の一括編集時に持っている「変更していない項目も一括編集する」が "ON" のときの動作について
        if (Object.keys(bulkEditInfo).length === 0) {
          bulkEditDataInfo = bulkEditSelectedRow[0]; // IQX_WEBEST-312 工程パターン（共通と板金、両方）の一括編集時に持っている「変更していない項目も一括編集する」が "ON" のときの動作について
        }
        if (isAllEdit) {
          bulkData.details = bulkEditDataInfo?.details;
        } else {
          for (let j = 0; j < bulkEditDataInfo?.details?.length; j++) {
            if (bulkEditDataInfo?.details[j]?.isUsedForCalc) {
              let editIndex = bulkData?.details?.findIndex(
                (item) => item.processId === bulkEditDataInfo?.details[j]?.processId
              );
              if (editIndex >= 0) {
                bulkData.details[editIndex] = bulkEditDataInfo?.details[j];
              }
            }
          }
        }
        editDatas.push(bulkData);
      }
      let updatedItems = await bulkEditProcessPatternData(editDatas);
      if (updatedItems) {
        if (getCurrentServiceClass() === ServiceClass.Parent) {
          // 親部品の場合、
          updatedItems = updatedItems?.filter((item) => item.class === ServiceClass.Parent);
        } else if (getCurrentServiceClass() === ServiceClass.SheetMetal) {
          // 板金の場合、
          updatedItems = updatedItems?.filter((item) => item.class === ServiceClass.SheetMetal);
        } else if (getCurrentServiceClass() === ServiceClass.Beams) {
          // 形鋼の場合、
          updatedItems = updatedItems?.filter((item) => item.class === ServiceClass.Beams);
        }
        if (updatedItems?.length > 0) {
          setInitialTableItems(updatedItems);
          let selectedUpdatedData = updatedItems?.filter((item) => item.id === selectedData?.id);
          if (selectedUpdatedData?.length > 0) {
            setSelectedData(selectedUpdatedData?.[0]);
            setSelectedRowKeys(selectedUpdatedData?.[0]?.id);
          }
        }
      }
    }
  };

  // 入力項目情報
  const inputFormItem = (
    <ProcessPatternForm
      ref={detailRef}
      selectedData={bulkEditSelectedRow?.[0] === undefined ? selectedData : bulkEditSelectedRow?.[0]} // IQX_WEBEST-313 工程パターン（共通と板金、両方）一括編集時に表示される装置について
      listData={initialTblItems}
      editMode={true}
      updateEditMode={updateEditMode}
      page={getCurrentServiceClass() === ServiceClass.Parent ? patternPage[0] : patternPage[1]}
      isParameter={false}
      updateKouteiSentakuInfo={updateKouteiSentakuInfo}
      kouteiSentakuEditMode={true}
      pmxMode={false}
      processMasterData={processMasterData}
      deviceMasterData={deviceMasterData}
      clientMasterData={clientMasterData}
      isBulkEdit={true}
    />
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={10}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={initialTblItems}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.id == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: totalCount,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultCurrent: 1,
              pageSize: editPageSize,
              pageSizeOptions: pageSizes,
              current: editPage,
              showSizeChanger: true,
              onShowSizeChange: handlePageSizeChangeEdit,
              onChange: (page) => {
                setEditPage(page);
              },
            }}
            onChange={handleTableChange}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={14}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <Checkbox
                style={{ marginRight: 10 }}
                type="checkbox"
                checked={isAllEdit}
                onChange={(e) => {
                  setIsAllEdit(e.target.checked);
                }}
              >
                変更していない項目も一括編集する
              </Checkbox>
            </div>
            <div style={{ height: 725, overflowY: 'auto' }}>{inputFormItem}</div>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );

  return (
    <>
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            <Row style={{ marginTop: 10 }}>
              <Table
                showSorterTooltip={false}
                className="pattern_tbListShow"
                rowClassName={(record, index) => (record.id == selectedRowKeys ? 'active-row' : 'data-row')}
                columns={tableColumns}
                rowKey={(record) => record.id}
                dataSource={initialTblItems}
                scroll={{ y: 590, x: '10vw' }}
                pagination={{
                  position: ['bottomLeft'],
                  total: totalCount,
                  showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                  // defaultPageSize: defaultPageSize,
                  defaultCurrent: 1,
                  // pageSize: defaultPageSize,
                  pageSize: pageSize,
                  pageSizeOptions: pageSizes,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: currentPage,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                      onRowSelect(record);
                    },
                  };
                }}
                onChange={handleTableChange}
                loading={tblLoading}
              />
            </Row>
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":parent.patternProcess"
                element={
                  <ProcessPatternDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    listData={initialTblItems}
                    getInitData={getInitData}
                    updateEditMode={updateEditMode}
                    processMasterData={processMasterData}
                    deviceMasterData={deviceMasterData}
                    clientMasterData={clientMasterData}
                    updateProcessPatternList={updateProcessPatternList}
                    updateSelectedData={updateSelectedData}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    formValidation={formValidation}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(addOK, addCancel),
            null,
            400,
            addOK,
            addCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規Modal */}
      {addNewModal
        ? commonModal(
            addNewModal,
            <div
              style={{
                width: 1200,
                backgroundColor: '#005fab',
                marginLeft: -24,
                height: 36,
                marginTop: -20,
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                fontSize: 14,
              }}
            >
              <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規追加</p>
            </div>,
            commonActionFooter(confirmOk, confirmCancel, '登録', '破棄'),
            { height: 720 },
            1200,
            confirmOk,
            confirmCancel,
            addNewContent,
            null,
            null,
            true
          )
        : ''}

      {/** 削除の確認メッセージ */}
      {deletePatternInfoModal
        ? commonModal(
            deletePatternInfoModal,
            confirmModalTitle,
            commonDeleteFooter(deleteOK, deleteCancel),
            null,
            400,
            deleteOK,
            deleteCancel,
            confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/** コビー元を使う場合 IQX_WEBEST-259 */}
      {copyCheckMsg
        ? commonModal(
            copyCheckMsg,
            confirmScreenChangeTitle,
            commonFooter(copyConfirmOk, copyConfirmCancel),
            null,
            400,
            copyConfirmOk,
            copyConfirmCancel,
            copyCheckMsgContent,
            null,
            null,
            false
          )
        : ''}

      {/* 一括編集 IQX_WEBEST-225　*/}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
    </>
  );
});

export default ProcessPatternParamList;
