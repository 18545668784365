/**
 * クラス名：ヘッダー画面
 * 説明：システムのヘッダー画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Card, Image, Space, Tooltip } from 'antd';
import React, { forwardRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '../assets/images/logo.ico';
import user from '../assets/images/user.png';
import company from '../assets/images/company.png';
import usersetting from '../assets/images/usersetting.png';
import { logOutSubmit } from '../views/common/CommonAPI';
import logout from '../assets/images/logout.png';

const Header = (props, ref) => {
  const [loginUser, setLoginUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setLoginUser(JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser'))));
  }, []);

  const showSetting = () => {
    navigate('/home/:userSetting');
  };

  //　ログアウトボタンの押下
  const handleClick = async (e) => {
    e.preventDefault();
    const result = await logOutSubmit();
    if (result) {
      navigate('/login');
    }
  };
  return (
    <div style={{ backgroundColor: '#1063aa', display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '60%',
        }}
      >
        <Space size="large">
          <Image preview={false} width={35} src={logo} style={{ marginLeft: '5px', marginTop: '0px' }}></Image>
          {/** IQX_WEBEST-169 */}
          <h5 style={{ color: 'whitesmoke', marginLeft: '-13px', marginTop: '3px' }}>iQx-WebEstimate (1.0.69)</h5>
        </Space>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          width: '39%',
        }}
      >
        <Image preview={false} width={32} src={company} style={{ marginLeft: '25px' }}></Image>
        <p
          style={{
            color: 'whitesmoke',
            marginLeft: '28px',
            fontSize: '14px',
            marginRight: '20px',
            marginTop: '10px',
          }}
        >
          {loginUser?.companyName}
        </p>
        {/* <Card style={{ display: 'flex', alignItems: 'center', width: 180 }}> */}
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <Image preview={false} width={24} src={user}></Image>
          <p style={{ color: 'white', marginLeft: '5px', fontSize: '14px', fontWeight: 'bold', marginTop: '15px' }}>
            {loginUser?.userName}
          </p>
        </div>
        {/* ログアウト */}
        <div
          className="menu-active-hover"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '13%',
            marginLeft: '10px',
            backgroundColor: '#f8f9fa',
            borderRadius: 10,
            cursor: 'pointer',
            height: 32,
          }}
          onClick={handleClick}
        >
          <Image preview={false} width={26} src={logout} style={{ marginLeft: '0px' }}></Image>
          <p
            className="menu"
            style={{
              color: '#4a4747',
              fontSize: '10.5px',
              fontWeight: 'bold',
              marginTop: 16,
              marginLeft: '2px',
            }}
          >
            ログアウト
          </p>
        </div>
        {/* 設定 */}
        <div
          // onClick={handleClick}
          style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '5%', marginLeft: '10px' }}
        >
          <Tooltip title="設定" placement="bottom" overlayClassName="tooltip-text">
            <Image
              preview={false}
              width={37}
              src={usersetting}
              onClick={showSetting}
              style={{ cursor: 'pointer' }}
            ></Image>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Header;
