/**
 * クラス名：鋼材区分一覧画面
 * 説明：iQ3板金に使用の鋼材区分一覧ファイルです。
 * 作成者：テッ
 * 作成日：2024/10/01
 * バージョン：1.0
 */
import { Table, Button, Row, Col, Image, Checkbox, Typography } from 'antd';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import 'split-pane-react/esm/themes/default.css';
import { arrayMoveImmutable } from 'array-move';

import '../../../../assets/styles/common.css';
import { actionArr, formatDateString, todayTime, SteelMaterialClass, compareIQ5Info } from '../../../common/Common.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import circle from '../../../../assets/images/circle.jpg';
import { getSteelShapeInfo, updateSteelShapeInfo } from '../../../common/CommonAPI.js';
import {
  commonFooter,
  commonModal,
  complete,
  confirmDiscardContent,
  confirmModalTitle,
  confirmUpdateContent,
} from '../../../common/CommonModal.js';

dayjs.extend(customParseFormat);

const SteelMaterialClassList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getEditMode: editMode,
    updateEditMode: setEditMode,
    getCsvExportFileName: csvExportFileName,
  }));

  const csvExportFileName = `鋼材区分_${formatDateString(todayTime())}.csv`;
  const [filteredData, setFilteredData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortNm, setSortNm] = useState('id');
  const [curIndex, setCurIndex] = useState(0); // 現在Index
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [filteredDataBK, setFilteredDataBk] = useState([]);
  const [isDiff, setIsDiff] = useState(false);

  useEffect(() => {
    getMaterialClassIQ5Data();
  }, []);

  // 鋼材区分マスタ情報を取得する
  const getMaterialClassIQ5Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let steelMaterialClassData = [];
    let pageBefore =
      props.initialParam === 'iq5_steelMaterialClass' &&
      props?.rowSelectPage !== undefined &&
      props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    const steelMaterialClassInfo = await getSteelShapeInfo();
    if (steelMaterialClassInfo) {
      steelMaterialClassData = steelMaterialClassInfo ? steelMaterialClassInfo : [];
    }
    setFilteredData(steelMaterialClassData);
    setFilteredDataBk(JSON.parse(JSON.stringify(steelMaterialClassData)));
    if (steelMaterialClassData?.length > 0) {
      setSelectedRowId(steelMaterialClassData[0]?.id);
      // 前回選択された行がある場合
      if (
        props.initialParam === 'iq5_steelMaterialClass' &&
        props.selectedRowId !== undefined &&
        props.selectedRowId !== 0
      ) {
        let selectedRowData = steelMaterialClassData?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          setSelectedRowId(selectedRowData?.[0]?.id);
        }
      }
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const onRowSelect = (record, index) => {
    setCurIndex(index);
    setSelectedRowId(record.id);
    props?.updateSelectedRowId(record?.id, 0, currentPage, 'ASC', sortNm, []);
    props?.resetInitialParam();
  };

  // mode「1：最初,2：一箇所前,3：一箇所後,4：最後」
  const onSortEnd = (e, mode) => {
    var maxIndex = filteredData?.length - 1; // リストの最大Index
    var oldIndex = curIndex; // 元Index
    var newIndex = curIndex; // 新Index

    //　現在、最初にある場合「最初、一箇所前へ」に移動不可。
    if (oldIndex === 0 && (mode === 1 || mode === 2)) return;
    //　現在、最後にある場合「一箇所後、最後へ」に移動不可。
    if (oldIndex === maxIndex && (mode === 3 || mode === 4)) return;

    // 最初へのボタンを押下
    if (mode === 1) newIndex = 0;
    // 一箇所前へのボタンを押下
    if (mode === 2) newIndex = newIndex - 1;
    // 一箇所後へのボタンを押下
    if (mode === 3) newIndex = newIndex + 1;
    //　最後へのボタンを押下
    if (mode === 4) newIndex = maxIndex;

    var newItems = arrayMoveImmutable(filteredData, oldIndex, newIndex);
    newItems?.map((item, index) => {
      item.shapeOrder = index + 1;
    });
    // Indexの切り替え
    setFilteredData(newItems);
    let isDiff = compareIQ5Info(filteredDataBK, newItems);
    setIsDiff(isDiff);
    setEditMode(isDiff);
    // 行の選択
    changeRowSelect(newIndex);
  };

  // Scrollの移動
  const scrollTo = (index) => {
    // Scrollの移動
    document.getElementById('sortId' + index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // 行の選択
  const changeRowSelect = (index) => {
    setCurIndex(index);
    scrollTo(index);
  };

  const updateData = () => {
    setUpdateConfirm(true);
  };

  const discardData = () => {
    setCancelConfirm(true);
  };

  const updConfirmOk = async (e) => {
    setUpdateConfirm(false);
    let updateDatas = await updateSteelShapeInfo(filteredData);
    if (updateDatas && updateDatas?.length > 0) {
      setFilteredData(updateDatas);
      setFilteredDataBk(JSON.parse(JSON.stringify(updateDatas)));
      setIsDiff(false);
      setEditMode(false);
      complete('データ更新は正常に完了しました。');
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    setCancelConfirm(false);
    setFilteredData(filteredDataBK);
    setIsDiff(false);
    setEditMode(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  const handleUsedChkChange = (index, checked) => {
    let tempData = [...filteredData];
    tempData[index].used = checked;
    setFilteredData(tempData);
    let isDiff = compareIQ5Info(filteredDataBK, tempData);
    setIsDiff(isDiff);
    setEditMode(isDiff);
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 55,
      render: (id, record, index) => {
        return index + 1;
      },
      className: 'cm-a-right',
    },
    {
      key: 'used',
      title: '使用',
      dataIndex: 'used',
      width: 70,
      className: 'cm-a-center',
      render: (record, i, index) => {
        return (
          <Checkbox
            style={{ marginLeft: 10, marginRight: 10 }}
            type="checkbox"
            name={'used'}
            id={'used'}
            defaultChecked={false}
            checked={record}
            onChange={(e) => handleUsedChkChange(index, e.target.checked)}
          />
        );
      },
    },
    {
      key: 'metalProfiles',
      title: '鋼材区分',
      dataIndex: 'metalProfiles',
      width: 110,
      className: 'cm-a-center',
      render: (record, i) => {
        const steelClassInfo = SteelMaterialClass?.find((item) => item.value === record);
        return (
          <Typography.Text id={'sortId'} ellipsis={{ tooltip: [steelClassInfo?.name] }}>
            {steelClassInfo ? steelClassInfo?.name : ''}
          </Typography.Text>
        );
      },
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: 200,
      render: (record, i) => {
        return (
          <Typography.Text id={'typographyTxt'} ellipsis={{ tooltip: [record] }}>
            {record ? record : ''}
          </Typography.Text>
        );
      },
    },
    {
      key: 'shapeNo',
      title: 'イメージ',
      dataIndex: 'shapeNo',
      width: 100,
      className: 'cm-a-center',
      render: (record, i) => {
        let imgNm = '/images/SteelShape/' + record + '.Png';
        return <Image src={imgNm} alt="" width={50} preview={true} />;
      },
    },
    {
      key: 't1Flg',
      title: 'T1',
      dataIndex: 't1',
      width: 100,
      className: 'cm-a-center',
      render: (value) => (value ? <Image src={circle} alt="" width={16} preview={false} /> : ''),
    },
    {
      key: 't2Flg',
      title: 'T2',
      dataIndex: 't2',
      width: 100,
      className: 'cm-a-center',
      render: (value) => (value ? <Image src={circle} alt="" width={16} preview={false} /> : ''),
    },
    {
      key: 'aFlg',
      title: 'a',
      dataIndex: 'a',
      width: 100,
      className: 'cm-a-center',
      render: (value) => (value ? <Image src={circle} alt="" width={16} preview={false} /> : ''),
    },
    {
      key: 'bFlg',
      title: 'B',
      dataIndex: 'b',
      width: 100,
      className: 'cm-a-center',
      render: (value) => (value ? <Image src={circle} alt="" width={16} preview={false} /> : ''),
    },
    {
      key: 'cFlg',
      title: 'C',
      dataIndex: 'c',
      width: 100,
      className: 'cm-a-center',
      render: (value) => (value ? <Image src={circle} alt="" width={16} preview={false} /> : ''),
    },
  ];

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
          display: 'flex',
        }}
      >
        <div style={{ marginTop: 7, width: '90vw', float: 'left' }}>
          <>
            {/* 一覧アリア */}
            <Row>
              <Col span={19}>
                <Table
                  id="steelMaterialClass"
                  showSorterTooltip={false}
                  columns={columns}
                  dataSource={filteredData}
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.id}
                  pagination={false}
                  className="param_tbListShow_steelMaterialClass"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                />
              </Col>
              <Col span={2}>
                {/* ボタン */}
                <div
                  style={{
                    marginTop: 20,
                    float: 'left',
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginLeft: 7,
                  }}
                >
                  <Button
                    id="toTop"
                    onClick={(e) => {
                      onSortEnd(e, 1);
                    }}
                    className="sortButton"
                  >
                    <div className="multiArrow">
                      <ArrowUpOutlined />
                      <ArrowUpOutlined />
                    </div>
                  </Button>
                  <Button
                    style={{ marginTop: 10 }}
                    id="toOneUp"
                    onClick={(e) => {
                      onSortEnd(e, 2);
                    }}
                    className="sortButton"
                  >
                    <ArrowUpOutlined />
                  </Button>
                  <Button
                    style={{ marginTop: 10 }}
                    id="toOneDown"
                    onClick={(e) => {
                      onSortEnd(e, 3);
                    }}
                    className="sortButton"
                  >
                    <ArrowDownOutlined />
                  </Button>
                  <Button
                    style={{ marginTop: 10 }}
                    id="toBottom"
                    onClick={(e) => {
                      onSortEnd(e, 4);
                    }}
                    className="sortButton"
                  >
                    <div className="multiArrow">
                      <ArrowDownOutlined />
                      <ArrowDownOutlined />
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </div>
        <div
          style={{
            width: '10vw',
            marginTop: 7,
            float: 'right',
            marginRight: 10,
          }}
        >
          <Row
            justify="end"
            style={{
              marginBottom: 0,
            }}
          >
            <Button
              id="update"
              className={isDiff ? 'mainButton' : 'disabledButton'}
              style={{ fontSize: '13.5px' }}
              onClick={updateData}
            >
              更新
            </Button>
            <Button
              id="cancel"
              className={isDiff ? 'cancelButton' : 'disabledButton'}
              style={{ marginLeft: 10, fontSize: '13.5px' }}
              onClick={discardData}
            >
              破棄
            </Button>
          </Row>
        </div>
      </div>
      {/* 更新確認_Modal */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/* 破棄確認_Modal */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default SteelMaterialClassList;
